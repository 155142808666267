.filter-price {
  padding-bottom: 15px;

  .price-input {
    display: flex;

    > .form-group {
      &:first-child {
        position: relative;
        margin-right: 15px;

        &:after {
          content: "-";
          font-size: 20px;
          position: absolute;
          top: 50%;
          right: -20px;
          transform: translateY(-50%);
        }
      }

      &:last-child {
        margin-left: 15px;
      }
    }

    .form-control {
      height: 40px;
      padding: 10px;
    }
  }

  .noUi-horizontal {
    height: 6px;

    .noUi-handle {
      top: -4px;
      right: -5px #{'/*rtl:ignore*/'};
      height: 13px;
      width: 13px;
    }
  }

  .noUi-target {
    background: $border-color-lite;
    border: none;
    box-shadow: none;
  }

  .noUi-connect {
    //background: $color-default;
    background-image: $gradient;
    //background: var(--color-primary);
  }

  .noUi-connect,
  .noUi-origin {
    transform-origin: 0 100% #{'/*rtl:ignore*/'};
  }

  .noUi-handle {
    border: none;
    background: black;
    border-radius: 50%;
    box-shadow: none;
    cursor: ew-resize;

    &:before {
      content: none;
    }

    &:after {
      left: 2px;
      top: 2px;
      height: 9px;
      width: 9px;
      background: white;
      border-radius: 50%;
    }
  }
}