.product-rating {
  display: inline-flex;

  .back-stars {
    position: relative;
    display: flex;

    > i {
      margin-right: 1px;
      color: $color-gray-dark;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .front-stars {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    color: $color-yellow;
    overflow: hidden;

    > i {
      margin-right: 1px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .rating-count {
    font-size: 11px;
    line-height: 12px;
    margin: 1px 0 0 5px;
    color: $color-gray;
  }
}
