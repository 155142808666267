@charset "UTF-8";
/* fonts */
/* default theme colors */
/* colors */
/* screen size */
/* miscellaneous */
.single-feature {
  display: flex;
  justify-content: center;
}
.single-feature .feature-icon {
  margin-right: 13px;
}
.single-feature .feature-icon > i {
  font-size: 36px;
  color: #b0976d;
  color: var(--color-primary);
}
.single-feature .feature-details {
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-feature .feature-details > span {
  font-size: 14px;
  line-height: 22px;
  display: block;
  color: #dcdcdc;
  margin-top: 2px;
}
.single-feature h6 {
  font-size: 15px;
  line-height: 1.3;
  font-style: normal;
}

.strike {
  color: red;
  text-decoration: line-through;
}

.thamnail_img img {
  max-height: 150px;
}

.error-message {
  float: left;
  color: red;
}

.list-group-item.selected {
  background: #b0976d;
}

.nav-link {
  padding: 0.5rem 2rem;
}

.pointer {
  cursor: pointer;
}

.outline {
  color: transparent !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #b0976d;
}

.sign-in-with {
  font-size: 16px;
  display: block;
  margin-top: 25px;
  color: #dcdcdc;
  text-align: center;
}

.social-login {
  display: flex;
  margin-top: 13px;
  justify-content: center;
}
.social-login li a {
  font-size: 16px;
  display: flex;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.social-login li a:hover {
  opacity: 0.85;
}
.social-login li a.facebook {
  background: #3a559f;
}
.social-login li a.google {
  background: #d94936;
}

.filter-price {
  padding-bottom: 15px;
}
.filter-price .price-input {
  display: flex;
}
.filter-price .price-input > .form-group:first-child {
  position: relative;
  margin-right: 15px;
}
.filter-price .price-input > .form-group:first-child:after {
  content: "-";
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}
.filter-price .price-input > .form-group:last-child {
  margin-left: 15px;
}
.filter-price .price-input .form-control {
  height: 40px;
  padding: 10px;
}
.filter-price .noUi-horizontal {
  height: 6px;
}
.filter-price .noUi-horizontal .noUi-handle {
  top: -4px;
  right: -5px /*rtl:ignore*/;
  height: 13px;
  width: 13px;
}
.filter-price .noUi-target {
  background: #e5e5e5;
  border: none;
  box-shadow: none;
}
.filter-price .noUi-connect {
  background-image: linear-gradient(to right, #ccc, #fff);
}
.filter-price .noUi-connect,
.filter-price .noUi-origin {
  transform-origin: 0 100% /*rtl:ignore*/;
}
.filter-price .noUi-handle {
  border: none;
  background: black;
  border-radius: 50%;
  box-shadow: none;
  cursor: ew-resize;
}
.filter-price .noUi-handle:before {
  content: none;
}
.filter-price .noUi-handle:after {
  left: 2px;
  top: 2px;
  height: 9px;
  width: 9px;
  background: white;
  border-radius: 50%;
}

.product-rating {
  display: inline-flex;
}
.product-rating .back-stars {
  position: relative;
  display: flex;
}
.product-rating .back-stars > i {
  margin-right: 1px;
  color: #989898;
}
.product-rating .back-stars > i:last-child {
  margin-right: 0;
}
.product-rating .front-stars {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  color: #ffb136;
  overflow: hidden;
}
.product-rating .front-stars > i {
  margin-right: 1px;
}
.product-rating .front-stars > i:last-child {
  margin-right: 0;
}
.product-rating .rating-count {
  font-size: 11px;
  line-height: 12px;
  margin: 1px 0 0 5px;
  color: #dcdcdc;
}

.specification {
  padding: 0 30px;
}
.specification .specification-inner {
  border: 1px solid #ebebeb;
  border-radius: 2px;
}
.specification .title {
  padding: 15px 30px;
  background: #f9f9f9;
}

.specification-list {
  padding: 35px 30px 34px 30px;
}
.specification-list li {
  display: flex;
  margin-bottom: 18px;
}
.specification-list li:last-child {
  margin-bottom: 0;
}
.specification-list label {
  font-weight: 500;
  line-height: 23px;
  width: 190px;
  min-width: 190px;
  margin-bottom: 0;
}
.specification-list span {
  line-height: 23px;
  color: #dcdcdc;
}

@media screen and (max-width: 991px) {
  .specification {
    padding: 41px 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .specification-list li {
    flex-direction: column;
  }
  .specification-list label {
    margin-bottom: 3px;
  }
}
.features-wrap {
  margin-top: 10px;
  width: 100%;
}
.features-wrap .features {
  padding-bottom: 0px;
  border-radius: 2px;
  background: #dcdcdc;
  color: #989898;
  padding: 5px 0;
}

.features-wrap .feature-list {
  display: flex;
  flex-wrap: wrap;
}
.features-wrap .single-feature {
  position: relative;
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}
.features-wrap .single-feature:last-child:after {
  width: 0;
}

@media screen and (max-width: 1280px) {
  .features-wrap .single-feature {
    flex: 0 0 20%;
    max-width: 20%;
    justify-content: flex-start;
    padding: 0 30px;
  }
  .features-wrap .single-feature:after {
    content: none;
  }
}
@media screen and (max-width: 1080px) {
  .features-wrap .single-feature {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media screen and (max-width: 780px) {
  .features-wrap .single-feature {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 576px) {
  .features-wrap .single-feature {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.reviews {
  padding-top: 45px;
}
.reviews .pagination {
  margin-top: 11px;
}

.review-form > h4 {
  margin-bottom: 28px;
}
.review-form .rating-input {
  font-size: 20px;
  line-height: 20px;
  position: relative;
  display: flex;
  margin: -1px 0 -2px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.review-form .rating-input label {
  margin-bottom: 0;
  color: #ebebeb;
  cursor: pointer;
  transition: 150ms ease-in-out;
}
.review-form .rating-input label:hover, .review-form .rating-input label:focus {
  color: #ffb136;
}
.review-form .rating-input label:hover ~ label, .review-form .rating-input label:focus ~ label {
  color: #ffb136;
}
.review-form .rating-input input[type=radio] {
  display: none;
}
.review-form .rating-input input[type=radio]:checked ~ label {
  color: #ffb136;
}
.user-review-wrap {
  margin-top: 4px;
}
.user-review-wrap.loading:after {
  border-width: 2px;
}
.user-review-wrap .empty-message {
  display: flex;
  padding: 35px 0 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.user-review-wrap .empty-message svg {
  height: 80px;
  width: 80px;
  margin-bottom: 16px;
}
.user-review-wrap .empty-message svg path {
  stroke-width: 3px;
  fill: #b0976d;
  fill: var(--color-primary);
  stroke: #b0976d;
  stroke: var(--color-primary);
}

.user-review {
  margin-bottom: 27px;
}
.user-review:nth-child(4) {
  margin-bottom: 0;
}
.user-review:nth-child(n+5) {
  display: none;
}
.user-review .reviewer-name {
  font-size: 16px;
  margin-bottom: 6px;
}
.user-review .product-rating {
  margin-bottom: 11px;
}
.user-review .reviewer-message {
  color: #dcdcdc;
  margin-bottom: 7px;
}
.user-review .review-date,
.user-review .review-approval {
  font-size: 14px;
  color: #989898;
}

@media screen and (max-width: 1199px) {
  .user-review-wrap {
    margin: 48px 0 8px;
  }
}
@media screen and (max-width: 991px) {
  .reviews {
    padding-top: 35px;
  }
}
.lar,
.las,
.lab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@font-face {
  font-family: Line Awesome Brands;
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/la-brands-400.eot");
  src: url("../fonts/la-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/la-brands-400.woff2") format("woff2"), url("../fonts/la-brands-400.woff") format("woff"), url("../fonts/la-brands-400.ttf") format("truetype"), url("../fonts/la-brands-400.svg#lineawesome") format("svg");
}
.lab {
  font-family: Line Awesome Brands;
  font-weight: 400;
}

@font-face {
  font-family: Line Awesome Free;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/la-regular-400.eot");
  src: url("../fonts/la-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/la-regular-400.woff2") format("woff2"), url("../fonts/la-regular-400.woff") format("woff"), url("../fonts/la-regular-400.ttf") format("truetype"), url("../fonts/la-regular-400.svg#lineawesome") format("svg");
}
.lar {
  font-family: Line Awesome Free;
  font-weight: 400;
}

@font-face {
  font-family: Line Awesome Free;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/la-solid-900.eot");
  src: url("../fonts/la-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/la-solid-900.woff2") format("woff2"), url("../fonts/la-solid-900.woff") format("woff"), url("../fonts/la-solid-900.ttf") format("truetype"), url("../fonts/la-solid-900.svg#lineawesome") format("svg");
}
.las {
  font-family: Line Awesome Free;
  font-weight: 900;
}

.la-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.la-xs {
  font-size: 0.75em;
}

.la-2x {
  font-size: 1em;
}

.la-2x {
  font-size: 2em;
}

.la-3x {
  font-size: 3em;
}

.la-4x {
  font-size: 4em;
}

.la-5x {
  font-size: 5em;
}

.la-6x {
  font-size: 6em;
}

.la-7x {
  font-size: 7em;
}

.la-8x {
  font-size: 8em;
}

.la-9x {
  font-size: 9em;
}

.la-10x {
  font-size: 10em;
}

.la-fw {
  text-align: center;
  width: 1.25em;
}

.la-fw {
  width: 1.25em;
  text-align: center;
}

.la-ul {
  padding-left: 0;
  margin-left: 1.4285714286em;
  list-style-type: none;
}
.la-ul > li {
  position: relative;
}

.la-li {
  position: absolute;
  left: -2em;
  text-align: center;
  width: 1.4285714286em;
  line-height: inherit;
}
.la-li.la-lg {
  left: -1.1428571429em;
}

.la-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.la-pull-left {
  float: left;
}

.la-pull-right {
  float: right;
}

.la.la-pull-left {
  margin-right: 0.3em;
}
.la.la-pull-right {
  margin-left: 0.3em;
}

.la.pull-left {
  margin-right: 0.3em;
}
.la.pull-right {
  margin-left: 0.3em;
}

.la-pull-left {
  float: left;
}

.la-pull-right {
  float: right;
}

.la.la-pull-left,
.las.la-pull-left,
.lar.la-pull-left,
.lal.la-pull-left,
.lab.la-pull-left {
  margin-right: 0.3em;
}

.la.la-pull-right,
.las.la-pull-right,
.lar.la-pull-right,
.lal.la-pull-right,
.lab.la-pull-right {
  margin-left: 0.3em;
}

.la-spin {
  -webkit-animation: la-spin 2s infinite linear;
  animation: la-spin 2s infinite linear;
}

.la-pulse {
  -webkit-animation: la-spin 1s infinite steps(8);
  animation: la-spin 1s infinite steps(8);
}

@-webkit-keyframes la-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes la-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.la-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.la-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.la-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.la-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.la-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.la-flip-both, .la-flip-horizontal.la-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .la-rotate-90,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-flip-horizontal,
:root .la-flip-vertical,
:root .la-flip-both {
  -webkit-filter: none;
  filter: none;
}

.la-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.la-stack-1x,
.la-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.la-stack-1x {
  line-height: inherit;
}

.la-stack-2x {
  font-size: 2em;
}

.la-inverse {
  color: #fff;
}

.la-500px:before {
  content: "\f26e";
}

.la-accessible-icon:before {
  content: "\f368";
}

.la-accusoft:before {
  content: "\f369";
}

.la-acquisitions-incorporated:before {
  content: "\f6af";
}

.la-ad:before {
  content: "\f641";
}

.la-address-book:before {
  content: "\f2b9";
}

.la-address-card:before {
  content: "\f2bb";
}

.la-adjust:before {
  content: "\f042";
}

.la-adn:before {
  content: "\f170";
}

.la-adobe:before {
  content: "\f778";
}

.la-adversal:before {
  content: "\f36a";
}

.la-affiliatetheme:before {
  content: "\f36b";
}

.la-air-freshener:before {
  content: "\f5d0";
}

.la-airbnb:before {
  content: "\f834";
}

.la-algolia:before {
  content: "\f36c";
}

.la-align-center:before {
  content: "\f037";
}

.la-align-justify:before {
  content: "\f039";
}

.la-align-left:before {
  content: "\f036";
}

.la-align-right:before {
  content: "\f038";
}

.la-alipay:before {
  content: "\f642";
}

.la-allergies:before {
  content: "\f461";
}

.la-amazon:before {
  content: "\f270";
}

.la-amazon-pay:before {
  content: "\f42c";
}

.la-ambulance:before {
  content: "\f0f9";
}

.la-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.la-amilia:before {
  content: "\f36d";
}

.la-anchor:before {
  content: "\f13d";
}

.la-android:before {
  content: "\f17b";
}

.la-angellist:before {
  content: "\f209";
}

.la-angle-double-down:before {
  content: "\f103";
}

.la-angle-double-left:before {
  content: "\f100";
}

.la-angle-double-right:before {
  content: "\f101";
}

.la-angle-double-up:before {
  content: "\f102";
}

.la-angle-down:before {
  content: "\f107";
}

.la-angle-left:before {
  content: "\f104";
}

.la-angle-right:before {
  content: "\f105";
}

.la-angle-up:before {
  content: "\f106";
}

.la-angry:before {
  content: "\f556";
}

.la-angrycreative:before {
  content: "\f36e";
}

.la-angular:before {
  content: "\f420";
}

.la-ankh:before {
  content: "\f644";
}

.la-app-store:before {
  content: "\f36f";
}

.la-app-store-ios:before {
  content: "\f370";
}

.la-apper:before {
  content: "\f371";
}

.la-apple:before {
  content: "\f179";
}

.la-apple-alt:before {
  content: "\f5d1";
}

.la-apple-pay:before {
  content: "\f415";
}

.la-archive:before {
  content: "\f187";
}

.la-archway:before {
  content: "\f557";
}

.la-arrow-alt-circle-down:before {
  content: "\f358";
}

.la-arrow-alt-circle-left:before {
  content: "\f359";
}

.la-arrow-alt-circle-right:before {
  content: "\f35a";
}

.la-arrow-alt-circle-up:before {
  content: "\f35b";
}

.la-arrow-circle-down:before {
  content: "\f0ab";
}

.la-arrow-circle-left:before {
  content: "\f0a8";
}

.la-arrow-circle-right:before {
  content: "\f0a9";
}

.la-arrow-circle-up:before {
  content: "\f0aa";
}

.la-arrow-down:before {
  content: "\f063";
}

.la-arrow-left:before {
  content: "\f060";
}

.la-arrow-right:before {
  content: "\f061";
}

.la-arrow-up:before {
  content: "\f062";
}

.la-arrows-alt:before {
  content: "\f0b2";
}

.la-arrows-alt-h:before {
  content: "\f337";
}

.la-arrows-alt-v:before {
  content: "\f338";
}

.la-artstation:before {
  content: "\f77a";
}

.la-assistive-listening-systems:before {
  content: "\f2a2";
}

.la-asterisk:before {
  content: "\f069";
}

.la-asymmetrik:before {
  content: "\f372";
}

.la-at:before {
  content: "\f1fa";
}

.la-atlas:before {
  content: "\f558";
}

.la-atlassian:before {
  content: "\f77b";
}

.la-atom:before {
  content: "\f5d2";
}

.la-audible:before {
  content: "\f373";
}

.la-audio-description:before {
  content: "\f29e";
}

.la-autoprefixer:before {
  content: "\f41c";
}

.la-avianex:before {
  content: "\f374";
}

.la-aviato:before {
  content: "\f421";
}

.la-award:before {
  content: "\f559";
}

.la-aws:before {
  content: "\f375";
}

.la-baby:before {
  content: "\f77c";
}

.la-baby-carriage:before {
  content: "\f77d";
}

.la-backspace:before {
  content: "\f55a";
}

.la-backward:before {
  content: "\f04a";
}

.la-bacon:before {
  content: "\f7e5";
}

.la-balance-scale:before {
  content: "\f24e";
}

.la-balance-scale-left:before {
  content: "\f515";
}

.la-balance-scale-right:before {
  content: "\f516";
}

.la-ban:before {
  content: "\f05e";
}

.la-band-aid:before {
  content: "\f462";
}

.la-bandcamp:before {
  content: "\f2d5";
}

.la-barcode:before {
  content: "\f02a";
}

.la-bars:before {
  content: "\f0c9";
}

.la-baseball-ball:before {
  content: "\f433";
}

.la-basketball-ball:before {
  content: "\f434";
}

.la-bath:before {
  content: "\f2cd";
}

.la-battery-empty:before {
  content: "\f244";
}

.la-battery-full:before {
  content: "\f240";
}

.la-battery-half:before {
  content: "\f242";
}

.la-battery-quarter:before {
  content: "\f243";
}

.la-battery-three-quarters:before {
  content: "\f241";
}

.la-battle-net:before {
  content: "\f835";
}

.la-bed:before {
  content: "\f236";
}

.la-beer:before {
  content: "\f0fc";
}

.la-behance:before {
  content: "\f1b4";
}

.la-behance-square:before {
  content: "\f1b5";
}

.la-bell:before {
  content: "\f0f3";
}

.la-bell-slash:before {
  content: "\f1f6";
}

.la-bezier-curve:before {
  content: "\f55b";
}

.la-bible:before {
  content: "\f647";
}

.la-bicycle:before {
  content: "\f206";
}

.la-biking:before {
  content: "\f84a";
}

.la-bimobject:before {
  content: "\f378";
}

.la-binoculars:before {
  content: "\f1e5";
}

.la-biohazard:before {
  content: "\f780";
}

.la-birthday-cake:before {
  content: "\f1fd";
}

.la-bitbucket:before {
  content: "\f171";
}

.la-bitcoin:before {
  content: "\f379";
}

.la-bity:before {
  content: "\f37a";
}

.la-black-tie:before {
  content: "\f27e";
}

.la-blackberry:before {
  content: "\f37b";
}

.la-blender:before {
  content: "\f517";
}

.la-blender-phone:before {
  content: "\f6b6";
}

.la-blind:before {
  content: "\f29d";
}

.la-blog:before {
  content: "\f781";
}

.la-blogger:before {
  content: "\f37c";
}

.la-blogger-b:before {
  content: "\f37d";
}

.la-bluetooth:before {
  content: "\f293";
}

.la-bluetooth-b:before {
  content: "\f294";
}

.la-bold:before {
  content: "\f032";
}

.la-bolt:before {
  content: "\f0e7";
}

.la-bomb:before {
  content: "\f1e2";
}

.la-bone:before {
  content: "\f5d7";
}

.la-bong:before {
  content: "\f55c";
}

.la-book:before {
  content: "\f02d";
}

.la-book-dead:before {
  content: "\f6b7";
}

.la-book-medical:before {
  content: "\f7e6";
}

.la-book-open:before {
  content: "\f518";
}

.la-book-reader:before {
  content: "\f5da";
}

.la-bookmark:before {
  content: "\f02e";
}

.la-bootstrap:before {
  content: "\f836";
}

.la-border-all:before {
  content: "\f84c";
}

.la-border-none:before {
  content: "\f850";
}

.la-border-style:before {
  content: "\f853";
}

.la-bowling-ball:before {
  content: "\f436";
}

.la-box:before {
  content: "\f466";
}

.la-box-open:before {
  content: "\f49e";
}

.la-boxes:before {
  content: "\f468";
}

.la-braille:before {
  content: "\f2a1";
}

.la-brain:before {
  content: "\f5dc";
}

.la-bread-slice:before {
  content: "\f7ec";
}

.la-briefcase:before {
  content: "\f0b1";
}

.la-briefcase-medical:before {
  content: "\f469";
}

.la-broadcast-tower:before {
  content: "\f519";
}

.la-broom:before {
  content: "\f51a";
}

.la-brush:before {
  content: "\f55d";
}

.la-btc:before {
  content: "\f15a";
}

.la-buffer:before {
  content: "\f837";
}

.la-bug:before {
  content: "\f188";
}

.la-building:before {
  content: "\f1ad";
}

.la-bullhorn:before {
  content: "\f0a1";
}

.la-bullseye:before {
  content: "\f140";
}

.la-burn:before {
  content: "\f46a";
}

.la-buromobelexperte:before {
  content: "\f37f";
}

.la-bus:before {
  content: "\f207";
}

.la-bus-alt:before {
  content: "\f55e";
}

.la-business-time:before {
  content: "\f64a";
}

.la-buysellads:before {
  content: "\f20d";
}

.la-calculator:before {
  content: "\f1ec";
}

.la-calendar:before {
  content: "\f133";
}

.la-calendar-alt:before {
  content: "\f073";
}

.la-calendar-check:before {
  content: "\f274";
}

.la-calendar-day:before {
  content: "\f783";
}

.la-calendar-minus:before {
  content: "\f272";
}

.la-calendar-plus:before {
  content: "\f271";
}

.la-calendar-times:before {
  content: "\f273";
}

.la-calendar-week:before {
  content: "\f784";
}

.la-camera:before {
  content: "\f030";
}

.la-camera-retro:before {
  content: "\f083";
}

.la-campground:before {
  content: "\f6bb";
}

.la-canadian-maple-leaf:before {
  content: "\f785";
}

.la-candy-cane:before {
  content: "\f786";
}

.la-cannabis:before {
  content: "\f55f";
}

.la-capsules:before {
  content: "\f46b";
}

.la-car:before {
  content: "\f1b9";
}

.la-car-alt:before {
  content: "\f5de";
}

.la-car-battery:before {
  content: "\f5df";
}

.la-car-crash:before {
  content: "\f5e1";
}

.la-car-side:before {
  content: "\f5e4";
}

.la-caret-down:before {
  content: "\f0d7";
}

.la-caret-left:before {
  content: "\f0d9";
}

.la-caret-right:before {
  content: "\f0da";
}

.la-caret-square-down:before {
  content: "\f150";
}

.la-caret-square-left:before {
  content: "\f191";
}

.la-caret-square-right:before {
  content: "\f152";
}

.la-caret-square-up:before {
  content: "\f151";
}

.la-caret-up:before {
  content: "\f0d8";
}

.la-carrot:before {
  content: "\f787";
}

.la-cart-arrow-down:before {
  content: "\f218";
}

.la-cart-plus:before {
  content: "\f217";
}

.la-cash-register:before {
  content: "\f788";
}

.la-cat:before {
  content: "\f6be";
}

.la-cc-amazon-pay:before {
  content: "\f42d";
}

.la-cc-amex:before {
  content: "\f1f3";
}

.la-cc-apple-pay:before {
  content: "\f416";
}

.la-cc-diners-club:before {
  content: "\f24c";
}

.la-cc-discover:before {
  content: "\f1f2";
}

.la-cc-jcb:before {
  content: "\f24b";
}

.la-cc-mastercard:before {
  content: "\f1f1";
}

.la-cc-paypal:before {
  content: "\f1f4";
}

.la-cc-stripe:before {
  content: "\f1f5";
}

.la-cc-visa:before {
  content: "\f1f0";
}

.la-centercode:before {
  content: "\f380";
}

.la-centos:before {
  content: "\f789";
}

.la-certificate:before {
  content: "\f0a3";
}

.la-chair:before {
  content: "\f6c0";
}

.la-chalkboard:before {
  content: "\f51b";
}

.la-chalkboard-teacher:before {
  content: "\f51c";
}

.la-charging-station:before {
  content: "\f5e7";
}

.la-chart-area:before {
  content: "\f1fe";
}

.la-chart-bar:before {
  content: "\f080";
}

.la-chart-line:before {
  content: "\f201";
}

.la-chart-pie:before {
  content: "\f200";
}

.la-check:before {
  content: "\f00c";
}

.la-check-circle:before {
  content: "\f058";
}

.la-check-double:before {
  content: "\f560";
}

.la-check-square:before {
  content: "\f14a";
}

.la-cheese:before {
  content: "\f7ef";
}

.la-chess:before {
  content: "\f439";
}

.la-chess-bishop:before {
  content: "\f43a";
}

.la-chess-board:before {
  content: "\f43c";
}

.la-chess-king:before {
  content: "\f43f";
}

.la-chess-knight:before {
  content: "\f441";
}

.la-chess-pawn:before {
  content: "\f443";
}

.la-chess-queen:before {
  content: "\f445";
}

.la-chess-rook:before {
  content: "\f447";
}

.la-chevron-circle-down:before {
  content: "\f13a";
}

.la-chevron-circle-left:before {
  content: "\f137";
}

.la-chevron-circle-right:before {
  content: "\f138";
}

.la-chevron-circle-up:before {
  content: "\f139";
}

.la-chevron-down:before {
  content: "\f078";
}

.la-chevron-left:before {
  content: "\f053";
}

.la-chevron-right:before {
  content: "\f054";
}

.la-chevron-up:before {
  content: "\f077";
}

.la-child:before {
  content: "\f1ae";
}

.la-chrome:before {
  content: "\f268";
}

.la-chromecast:before {
  content: "\f838";
}

.la-church:before {
  content: "\f51d";
}

.la-circle:before {
  content: "\f111";
}

.la-circle-notch:before {
  content: "\f1ce";
}

.la-city:before {
  content: "\f64f";
}

.la-clinic-medical:before {
  content: "\f7f2";
}

.la-clipboard:before {
  content: "\f328";
}

.la-clipboard-check:before {
  content: "\f46c";
}

.la-clipboard-list:before {
  content: "\f46d";
}

.la-clock:before {
  content: "\f017";
}

.la-clone:before {
  content: "\f24d";
}

.la-closed-captioning:before {
  content: "\f20a";
}

.la-cloud:before {
  content: "\f0c2";
}

.la-cloud-download-alt:before {
  content: "\f381";
}

.la-cloud-meatball:before {
  content: "\f73b";
}

.la-cloud-moon:before {
  content: "\f6c3";
}

.la-cloud-moon-rain:before {
  content: "\f73c";
}

.la-cloud-rain:before {
  content: "\f73d";
}

.la-cloud-showers-heavy:before {
  content: "\f740";
}

.la-cloud-sun:before {
  content: "\f6c4";
}

.la-cloud-sun-rain:before {
  content: "\f743";
}

.la-cloud-upload-alt:before {
  content: "\f382";
}

.la-cloudscale:before {
  content: "\f383";
}

.la-cloudsmith:before {
  content: "\f384";
}

.la-cloudversify:before {
  content: "\f385";
}

.la-cocktail:before {
  content: "\f561";
}

.la-code:before {
  content: "\f121";
}

.la-code-branch:before {
  content: "\f126";
}

.la-codepen:before {
  content: "\f1cb";
}

.la-codiepie:before {
  content: "\f284";
}

.la-coffee:before {
  content: "\f0f4";
}

.la-cog:before {
  content: "\f013";
}

.la-cogs:before {
  content: "\f085";
}

.la-coins:before {
  content: "\f51e";
}

.la-columns:before {
  content: "\f0db";
}

.la-comment:before {
  content: "\f075";
}

.la-comment-alt:before {
  content: "\f27a";
}

.la-comment-dollar:before {
  content: "\f651";
}

.la-comment-dots:before {
  content: "\f4ad";
}

.la-comment-medical:before {
  content: "\f7f5";
}

.la-comment-slash:before {
  content: "\f4b3";
}

.la-comments:before {
  content: "\f086";
}

.la-comments-dollar:before {
  content: "\f653";
}

.la-compact-disc:before {
  content: "\f51f";
}

.la-compass:before {
  content: "\f14e";
}

.la-compress:before {
  content: "\f066";
}

.la-compress-arrows-alt:before {
  content: "\f78c";
}

.la-concierge-bell:before {
  content: "\f562";
}

.la-confluence:before {
  content: "\f78d";
}

.la-connectdevelop:before {
  content: "\f20e";
}

.la-contao:before {
  content: "\f26d";
}

.la-cookie:before {
  content: "\f563";
}

.la-cookie-bite:before {
  content: "\f564";
}

.la-copy:before {
  content: "\f0c5";
}

.la-copyright:before {
  content: "\f1f9";
}

.la-cotton-bureau:before {
  content: "\f89e";
}

.la-couch:before {
  content: "\f4b8";
}

.la-cpanel:before {
  content: "\f388";
}

.la-creative-commons:before {
  content: "\f25e";
}

.la-creative-commons-by:before {
  content: "\f4e7";
}

.la-creative-commons-nc:before {
  content: "\f4e8";
}

.la-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.la-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.la-creative-commons-nd:before {
  content: "\f4eb";
}

.la-creative-commons-pd:before {
  content: "\f4ec";
}

.la-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.la-creative-commons-remix:before {
  content: "\f4ee";
}

.la-creative-commons-sa:before {
  content: "\f4ef";
}

.la-creative-commons-sampling:before {
  content: "\f4f0";
}

.la-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.la-creative-commons-share:before {
  content: "\f4f2";
}

.la-creative-commons-zero:before {
  content: "\f4f3";
}

.la-credit-card:before {
  content: "\f09d";
}

.la-critical-role:before {
  content: "\f6c9";
}

.la-crop:before {
  content: "\f125";
}

.la-crop-alt:before {
  content: "\f565";
}

.la-cross:before {
  content: "\f654";
}

.la-crosshairs:before {
  content: "\f05b";
}

.la-crow:before {
  content: "\f520";
}

.la-crown:before {
  content: "\f521";
}

.la-crutch:before {
  content: "\f7f7";
}

.la-css3:before {
  content: "\f13c";
}

.la-css3-alt:before {
  content: "\f38b";
}

.la-cube:before {
  content: "\f1b2";
}

.la-cubes:before {
  content: "\f1b3";
}

.la-cut:before {
  content: "\f0c4";
}

.la-cuttlefish:before {
  content: "\f38c";
}

.la-d-and-d:before {
  content: "\f38d";
}

.la-d-and-d-beyond:before {
  content: "\f6ca";
}

.la-dashcube:before {
  content: "\f210";
}

.la-database:before {
  content: "\f1c0";
}

.la-deaf:before {
  content: "\f2a4";
}

.la-delicious:before {
  content: "\f1a5";
}

.la-democrat:before {
  content: "\f747";
}

.la-deploydog:before {
  content: "\f38e";
}

.la-deskpro:before {
  content: "\f38f";
}

.la-desktop:before {
  content: "\f108";
}

.la-dev:before {
  content: "\f6cc";
}

.la-deviantart:before {
  content: "\f1bd";
}

.la-dharmachakra:before {
  content: "\f655";
}

.la-dhl:before {
  content: "\f790";
}

.la-diagnoses:before {
  content: "\f470";
}

.la-diaspora:before {
  content: "\f791";
}

.la-dice:before {
  content: "\f522";
}

.la-dice-d20:before {
  content: "\f6cf";
}

.la-dice-d6:before {
  content: "\f6d1";
}

.la-dice-five:before {
  content: "\f523";
}

.la-dice-four:before {
  content: "\f524";
}

.la-dice-one:before {
  content: "\f525";
}

.la-dice-six:before {
  content: "\f526";
}

.la-dice-three:before {
  content: "\f527";
}

.la-dice-two:before {
  content: "\f528";
}

.la-digg:before {
  content: "\f1a6";
}

.la-digital-ocean:before {
  content: "\f391";
}

.la-digital-tachograph:before {
  content: "\f566";
}

.la-directions:before {
  content: "\f5eb";
}

.la-discord:before {
  content: "\f392";
}

.la-discourse:before {
  content: "\f393";
}

.la-divide:before {
  content: "\f529";
}

.la-dizzy:before {
  content: "\f567";
}

.la-dna:before {
  content: "\f471";
}

.la-dochub:before {
  content: "\f394";
}

.la-docker:before {
  content: "\f395";
}

.la-dog:before {
  content: "\f6d3";
}

.la-dollar-sign:before {
  content: "\f155";
}

.la-dolly:before {
  content: "\f472";
}

.la-dolly-flatbed:before {
  content: "\f474";
}

.la-donate:before {
  content: "\f4b9";
}

.la-door-closed:before {
  content: "\f52a";
}

.la-door-open:before {
  content: "\f52b";
}

.la-dot-circle:before {
  content: "\f192";
}

.la-dove:before {
  content: "\f4ba";
}

.la-download:before {
  content: "\f019";
}

.la-draft2digital:before {
  content: "\f396";
}

.la-drafting-compass:before {
  content: "\f568";
}

.la-dragon:before {
  content: "\f6d5";
}

.la-draw-polygon:before {
  content: "\f5ee";
}

.la-dribbble:before {
  content: "\f17d";
}

.la-dribbble-square:before {
  content: "\f397";
}

.la-dropbox:before {
  content: "\f16b";
}

.la-drum:before {
  content: "\f569";
}

.la-drum-steelpan:before {
  content: "\f56a";
}

.la-drumstick-bite:before {
  content: "\f6d7";
}

.la-drupal:before {
  content: "\f1a9";
}

.la-dumbbell:before {
  content: "\f44b";
}

.la-dumpster:before {
  content: "\f793";
}

.la-dumpster-fire:before {
  content: "\f794";
}

.la-dungeon:before {
  content: "\f6d9";
}

.la-dyalog:before {
  content: "\f399";
}

.la-earlybirds:before {
  content: "\f39a";
}

.la-ebay:before {
  content: "\f4f4";
}

.la-edge:before {
  content: "\f282";
}

.la-edit:before {
  content: "\f044";
}

.la-egg:before {
  content: "\f7fb";
}

.la-eject:before {
  content: "\f052";
}

.la-elementor:before {
  content: "\f430";
}

.la-ellipsis-h:before {
  content: "\f141";
}

.la-ellipsis-v:before {
  content: "\f142";
}

.la-ello:before {
  content: "\f5f1";
}

.la-ember:before {
  content: "\f423";
}

.la-empire:before {
  content: "\f1d1";
}

.la-envelope:before {
  content: "\f0e0";
}

.la-envelope-open:before {
  content: "\f2b6";
}

.la-envelope-open-text:before {
  content: "\f658";
}

.la-envelope-square:before {
  content: "\f199";
}

.la-envira:before {
  content: "\f299";
}

.la-equals:before {
  content: "\f52c";
}

.la-eraser:before {
  content: "\f12d";
}

.la-erlang:before {
  content: "\f39d";
}

.la-ethereum:before {
  content: "\f42e";
}

.la-ethernet:before {
  content: "\f796";
}

.la-etsy:before {
  content: "\f2d7";
}

.la-euro-sign:before {
  content: "\f153";
}

.la-evernote:before {
  content: "\f839";
}

.la-exchange-alt:before {
  content: "\f362";
}

.la-exclamation:before {
  content: "\f12a";
}

.la-exclamation-circle:before {
  content: "\f06a";
}

.la-exclamation-triangle:before {
  content: "\f071";
}

.la-expand:before {
  content: "\f065";
}

.la-expand-arrows-alt:before {
  content: "\f31e";
}

.la-expeditedssl:before {
  content: "\f23e";
}

.la-external-link-alt:before {
  content: "\f35d";
}

.la-external-link-square-alt:before {
  content: "\f360";
}

.la-eye:before {
  content: "\f06e";
}

.la-eye-dropper:before {
  content: "\f1fb";
}

.la-eye-slash:before {
  content: "\f070";
}

.la-facebook:before {
  content: "\f09a";
}

.la-facebook-f:before {
  content: "\f39e";
}

.la-facebook-messenger:before {
  content: "\f39f";
}

.la-facebook-square:before {
  content: "\f082";
}

.la-fan:before {
  content: "\f863";
}

.la-fantasy-flight-games:before {
  content: "\f6dc";
}

.la-fast-backward:before {
  content: "\f049";
}

.la-fast-forward:before {
  content: "\f050";
}

.la-fax:before {
  content: "\f1ac";
}

.la-feather:before {
  content: "\f52d";
}

.la-feather-alt:before {
  content: "\f56b";
}

.la-fedex:before {
  content: "\f797";
}

.la-fedora:before {
  content: "\f798";
}

.la-female:before {
  content: "\f182";
}

.la-fighter-jet:before {
  content: "\f0fb";
}

.la-figma:before {
  content: "\f799";
}

.la-file:before {
  content: "\f15b";
}

.la-file-alt:before {
  content: "\f15c";
}

.la-file-archive:before {
  content: "\f1c6";
}

.la-file-audio:before {
  content: "\f1c7";
}

.la-file-code:before {
  content: "\f1c9";
}

.la-file-contract:before {
  content: "\f56c";
}

.la-file-csv:before {
  content: "\f6dd";
}

.la-file-download:before {
  content: "\f56d";
}

.la-file-excel:before {
  content: "\f1c3";
}

.la-file-export:before {
  content: "\f56e";
}

.la-file-image:before {
  content: "\f1c5";
}

.la-file-import:before {
  content: "\f56f";
}

.la-file-invoice:before {
  content: "\f570";
}

.la-file-invoice-dollar:before {
  content: "\f571";
}

.la-file-medical:before {
  content: "\f477";
}

.la-file-medical-alt:before {
  content: "\f478";
}

.la-file-pdf:before {
  content: "\f1c1";
}

.la-file-powerpoint:before {
  content: "\f1c4";
}

.la-file-prescription:before {
  content: "\f572";
}

.la-file-signature:before {
  content: "\f573";
}

.la-file-upload:before {
  content: "\f574";
}

.la-file-video:before {
  content: "\f1c8";
}

.la-file-word:before {
  content: "\f1c2";
}

.la-fill:before {
  content: "\f575";
}

.la-fill-drip:before {
  content: "\f576";
}

.la-film:before {
  content: "\f008";
}

.la-filter:before {
  content: "\f0b0";
}

.la-fingerprint:before {
  content: "\f577";
}

.la-fire:before {
  content: "\f06d";
}

.la-fire-alt:before {
  content: "\f7e4";
}

.la-fire-extinguisher:before {
  content: "\f134";
}

.la-firefox:before {
  content: "\f269";
}

.la-first-aid:before {
  content: "\f479";
}

.la-first-order:before {
  content: "\f2b0";
}

.la-first-order-alt:before {
  content: "\f50a";
}

.la-firstdraft:before {
  content: "\f3a1";
}

.la-fish:before {
  content: "\f578";
}

.la-fist-raised:before {
  content: "\f6de";
}

.la-flag:before {
  content: "\f024";
}

.la-flag-checkered:before {
  content: "\f11e";
}

.la-flag-usa:before {
  content: "\f74d";
}

.la-flask:before {
  content: "\f0c3";
}

.la-flickr:before {
  content: "\f16e";
}

.la-flipboard:before {
  content: "\f44d";
}

.la-flushed:before {
  content: "\f579";
}

.la-fly:before {
  content: "\f417";
}

.la-folder:before {
  content: "\f07b";
}

.la-folder-minus:before {
  content: "\f65d";
}

.la-folder-open:before {
  content: "\f07c";
}

.la-folder-plus:before {
  content: "\f65e";
}

.la-font:before {
  content: "\f031";
}

.la-font-awesome:before {
  content: "\f2b4";
}

.la-font-awesome-alt:before {
  content: "\f35c";
}

.la-font-awesome-flag:before {
  content: "\f425";
}

.la-fonticons:before {
  content: "\f280";
}

.la-fonticons-fi:before {
  content: "\f3a2";
}

.la-football-ball:before {
  content: "\f44e";
}

.la-fort-awesome:before {
  content: "\f286";
}

.la-fort-awesome-alt:before {
  content: "\f3a3";
}

.la-forumbee:before {
  content: "\f211";
}

.la-forward:before {
  content: "\f04e";
}

.la-foursquare:before {
  content: "\f180";
}

.la-free-code-camp:before {
  content: "\f2c5";
}

.la-freebsd:before {
  content: "\f3a4";
}

.la-frog:before {
  content: "\f52e";
}

.la-frown:before {
  content: "\f119";
}

.la-frown-open:before {
  content: "\f57a";
}

.la-fulcrum:before {
  content: "\f50b";
}

.la-funnel-dollar:before {
  content: "\f662";
}

.la-futbol:before {
  content: "\f1e3";
}

.la-galactic-republic:before {
  content: "\f50c";
}

.la-galactic-senate:before {
  content: "\f50d";
}

.la-gamepad:before {
  content: "\f11b";
}

.la-gas-pump:before {
  content: "\f52f";
}

.la-gavel:before {
  content: "\f0e3";
}

.la-gem:before {
  content: "\f3a5";
}

.la-genderless:before {
  content: "\f22d";
}

.la-get-pocket:before {
  content: "\f265";
}

.la-gg:before {
  content: "\f260";
}

.la-gg-circle:before {
  content: "\f261";
}

.la-ghost:before {
  content: "\f6e2";
}

.la-gift:before {
  content: "\f06b";
}

.la-gifts:before {
  content: "\f79c";
}

.la-git:before {
  content: "\f1d3";
}

.la-git-alt:before {
  content: "\f841";
}

.la-git-square:before {
  content: "\f1d2";
}

.la-github:before {
  content: "\f09b";
}

.la-github-alt:before {
  content: "\f113";
}

.la-github-square:before {
  content: "\f092";
}

.la-gitkraken:before {
  content: "\f3a6";
}

.la-gitlab:before {
  content: "\f296";
}

.la-gitter:before {
  content: "\f426";
}

.la-glass-cheers:before {
  content: "\f79f";
}

.la-glass-martini:before {
  content: "\f000";
}

.la-glass-martini-alt:before {
  content: "\f57b";
}

.la-glass-whiskey:before {
  content: "\f7a0";
}

.la-glasses:before {
  content: "\f530";
}

.la-glide:before {
  content: "\f2a5";
}

.la-glide-g:before {
  content: "\f2a6";
}

.la-globe:before {
  content: "\f0ac";
}

.la-globe-africa:before {
  content: "\f57c";
}

.la-globe-americas:before {
  content: "\f57d";
}

.la-globe-asia:before {
  content: "\f57e";
}

.la-globe-europe:before {
  content: "\f7a2";
}

.la-gofore:before {
  content: "\f3a7";
}

.la-golf-ball:before {
  content: "\f450";
}

.la-goodreads:before {
  content: "\f3a8";
}

.la-goodreads-g:before {
  content: "\f3a9";
}

.la-google:before {
  content: "\f1a0";
}

.la-google-drive:before {
  content: "\f3aa";
}

.la-google-play:before {
  content: "\f3ab";
}

.la-google-plus:before {
  content: "\f2b3";
}

.la-google-plus-g:before {
  content: "\f0d5";
}

.la-google-plus-square:before {
  content: "\f0d4";
}

.la-google-wallet:before {
  content: "\f1ee";
}

.la-gopuram:before {
  content: "\f664";
}

.la-graduation-cap:before {
  content: "\f19d";
}

.la-gratipay:before {
  content: "\f184";
}

.la-grav:before {
  content: "\f2d6";
}

.la-greater-than:before {
  content: "\f531";
}

.la-greater-than-equal:before {
  content: "\f532";
}

.la-grimace:before {
  content: "\f57f";
}

.la-grin:before {
  content: "\f580";
}

.la-grin-alt:before {
  content: "\f581";
}

.la-grin-beam:before {
  content: "\f582";
}

.la-grin-beam-sweat:before {
  content: "\f583";
}

.la-grin-hearts:before {
  content: "\f584";
}

.la-grin-squint:before {
  content: "\f585";
}

.la-grin-squint-tears:before {
  content: "\f586";
}

.la-grin-stars:before {
  content: "\f587";
}

.la-grin-tears:before {
  content: "\f588";
}

.la-grin-tongue:before {
  content: "\f589";
}

.la-grin-tongue-squint:before {
  content: "\f58a";
}

.la-grin-tongue-wink:before {
  content: "\f58b";
}

.la-grin-wink:before {
  content: "\f58c";
}

.la-grip-horizontal:before {
  content: "\f58d";
}

.la-grip-lines:before {
  content: "\f7a4";
}

.la-grip-lines-vertical:before {
  content: "\f7a5";
}

.la-grip-vertical:before {
  content: "\f58e";
}

.la-gripfire:before {
  content: "\f3ac";
}

.la-grunt:before {
  content: "\f3ad";
}

.la-guitar:before {
  content: "\f7a6";
}

.la-gulp:before {
  content: "\f3ae";
}

.la-h-square:before {
  content: "\f0fd";
}

.la-hacker-news:before {
  content: "\f1d4";
}

.la-hacker-news-square:before {
  content: "\f3af";
}

.la-hackerrank:before {
  content: "\f5f7";
}

.la-hamburger:before {
  content: "\f805";
}

.la-hammer:before {
  content: "\f6e3";
}

.la-hamsa:before {
  content: "\f665";
}

.la-hand-holding:before {
  content: "\f4bd";
}

.la-hand-holding-heart:before {
  content: "\f4be";
}

.la-hand-holding-usd:before {
  content: "\f4c0";
}

.la-hand-lizard:before {
  content: "\f258";
}

.la-hand-middle-finger:before {
  content: "\f806";
}

.la-hand-paper:before {
  content: "\f256";
}

.la-hand-peace:before {
  content: "\f25b";
}

.la-hand-point-down:before {
  content: "\f0a7";
}

.la-hand-point-left:before {
  content: "\f0a5";
}

.la-hand-point-right:before {
  content: "\f0a4";
}

.la-hand-point-up:before {
  content: "\f0a6";
}

.la-hand-pointer:before {
  content: "\f25a";
}

.la-hand-rock:before {
  content: "\f255";
}

.la-hand-scissors:before {
  content: "\f257";
}

.la-hand-spock:before {
  content: "\f259";
}

.la-hands:before {
  content: "\f4c2";
}

.la-hands-helping:before {
  content: "\f4c4";
}

.la-handshake:before {
  content: "\f2b5";
}

.la-hanukiah:before {
  content: "\f6e6";
}

.la-hard-hat:before {
  content: "\f807";
}

.la-hashtag:before {
  content: "\f292";
}

.la-hat-wizard:before {
  content: "\f6e8";
}

.la-haykal:before {
  content: "\f666";
}

.la-hdd:before {
  content: "\f0a0";
}

.la-heading:before {
  content: "\f1dc";
}

.la-headphones:before {
  content: "\f025";
}

.la-headphones-alt:before {
  content: "\f58f";
}

.la-headset:before {
  content: "\f590";
}

.la-heart:before {
  content: "\f004";
}

.la-heart-broken:before {
  content: "\f7a9";
}

.la-heartbeat:before {
  content: "\f21e";
}

.la-helicopter:before {
  content: "\f533";
}

.la-highlighter:before {
  content: "\f591";
}

.la-hiking:before {
  content: "\f6ec";
}

.la-hippo:before {
  content: "\f6ed";
}

.la-hips:before {
  content: "\f452";
}

.la-hire-a-helper:before {
  content: "\f3b0";
}

.la-history:before {
  content: "\f1da";
}

.la-hockey-puck:before {
  content: "\f453";
}

.la-holly-berry:before {
  content: "\f7aa";
}

.la-home:before {
  content: "\f015";
}

.la-hooli:before {
  content: "\f427";
}

.la-hornbill:before {
  content: "\f592";
}

.la-horse:before {
  content: "\f6f0";
}

.la-horse-head:before {
  content: "\f7ab";
}

.la-hospital:before {
  content: "\f0f8";
}

.la-hospital-alt:before {
  content: "\f47d";
}

.la-hospital-symbol:before {
  content: "\f47e";
}

.la-hot-tub:before {
  content: "\f593";
}

.la-hotdog:before {
  content: "\f80f";
}

.la-hotel:before {
  content: "\f594";
}

.la-hotjar:before {
  content: "\f3b1";
}

.la-hourglass:before {
  content: "\f254";
}

.la-hourglass-end:before {
  content: "\f253";
}

.la-hourglass-half:before {
  content: "\f252";
}

.la-hourglass-start:before {
  content: "\f251";
}

.la-house-damage:before {
  content: "\f6f1";
}

.la-houzz:before {
  content: "\f27c";
}

.la-hryvnia:before {
  content: "\f6f2";
}

.la-html5:before {
  content: "\f13b";
}

.la-hubspot:before {
  content: "\f3b2";
}

.la-i-cursor:before {
  content: "\f246";
}

.la-ice-cream:before {
  content: "\f810";
}

.la-icicles:before {
  content: "\f7ad";
}

.la-icons:before {
  content: "\f86d";
}

.la-id-badge:before {
  content: "\f2c1";
}

.la-id-card:before {
  content: "\f2c2";
}

.la-id-card-alt:before {
  content: "\f47f";
}

.la-igloo:before {
  content: "\f7ae";
}

.la-image:before {
  content: "\f03e";
}

.la-images:before {
  content: "\f302";
}

.la-imdb:before {
  content: "\f2d8";
}

.la-inbox:before {
  content: "\f01c";
}

.la-indent:before {
  content: "\f03c";
}

.la-industry:before {
  content: "\f275";
}

.la-infinity:before {
  content: "\f534";
}

.la-info:before {
  content: "\f129";
}

.la-info-circle:before {
  content: "\f05a";
}

.la-instagram:before {
  content: "\f16d";
}

.la-intercom:before {
  content: "\f7af";
}

.la-internet-explorer:before {
  content: "\f26b";
}

.la-invision:before {
  content: "\f7b0";
}

.la-ioxhost:before {
  content: "\f208";
}

.la-italic:before {
  content: "\f033";
}

.la-itch-io:before {
  content: "\f83a";
}

.la-itunes:before {
  content: "\f3b4";
}

.la-itunes-note:before {
  content: "\f3b5";
}

.la-java:before {
  content: "\f4e4";
}

.la-jedi:before {
  content: "\f669";
}

.la-jedi-order:before {
  content: "\f50e";
}

.la-jenkins:before {
  content: "\f3b6";
}

.la-jira:before {
  content: "\f7b1";
}

.la-joget:before {
  content: "\f3b7";
}

.la-joint:before {
  content: "\f595";
}

.la-joomla:before {
  content: "\f1aa";
}

.la-journal-whills:before {
  content: "\f66a";
}

.la-js:before {
  content: "\f3b8";
}

.la-js-square:before {
  content: "\f3b9";
}

.la-jsfiddle:before {
  content: "\f1cc";
}

.la-kaaba:before {
  content: "\f66b";
}

.la-kaggle:before {
  content: "\f5fa";
}

.la-key:before {
  content: "\f084";
}

.la-keybase:before {
  content: "\f4f5";
}

.la-keyboard:before {
  content: "\f11c";
}

.la-keycdn:before {
  content: "\f3ba";
}

.la-khanda:before {
  content: "\f66d";
}

.la-kickstarter:before {
  content: "\f3bb";
}

.la-kickstarter-k:before {
  content: "\f3bc";
}

.la-kiss:before {
  content: "\f596";
}

.la-kiss-beam:before {
  content: "\f597";
}

.la-kiss-wink-heart:before {
  content: "\f598";
}

.la-kiwi-bird:before {
  content: "\f535";
}

.la-korvue:before {
  content: "\f42f";
}

.la-landmark:before {
  content: "\f66f";
}

.la-language:before {
  content: "\f1ab";
}

.la-laptop:before {
  content: "\f109";
}

.la-laptop-code:before {
  content: "\f5fc";
}

.la-laptop-medical:before {
  content: "\f812";
}

.la-laravel:before {
  content: "\f3bd";
}

.la-lastfm:before {
  content: "\f202";
}

.la-lastfm-square:before {
  content: "\f203";
}

.la-laugh:before {
  content: "\f599";
}

.la-laugh-beam:before {
  content: "\f59a";
}

.la-laugh-squint:before {
  content: "\f59b";
}

.la-laugh-wink:before {
  content: "\f59c";
}

.la-layer-group:before {
  content: "\f5fd";
}

.la-leaf:before {
  content: "\f06c";
}

.la-leanpub:before {
  content: "\f212";
}

.la-lemon:before {
  content: "\f094";
}

.la-less:before {
  content: "\f41d";
}

.la-less-than:before {
  content: "\f536";
}

.la-less-than-equal:before {
  content: "\f537";
}

.la-level-down-alt:before {
  content: "\f3be";
}

.la-level-up-alt:before {
  content: "\f3bf";
}

.la-life-ring:before {
  content: "\f1cd";
}

.la-lightbulb:before {
  content: "\f0eb";
}

.la-line:before {
  content: "\f3c0";
}

.la-link:before {
  content: "\f0c1";
}

.la-linkedin:before {
  content: "\f08c";
}

.la-linkedin-in:before {
  content: "\f0e1";
}

.la-linode:before {
  content: "\f2b8";
}

.la-linux:before {
  content: "\f17c";
}

.la-lira-sign:before {
  content: "\f195";
}

.la-list:before {
  content: "\f03a";
}

.la-list-alt:before {
  content: "\f022";
}

.la-list-ol:before {
  content: "\f0cb";
}

.la-list-ul:before {
  content: "\f0ca";
}

.la-location-arrow:before {
  content: "\f124";
}

.la-lock:before {
  content: "\f023";
}

.la-lock-open:before {
  content: "\f3c1";
}

.la-long-arrow-alt-down:before {
  content: "\f309";
}

.la-long-arrow-alt-left:before {
  content: "\f30a";
}

.la-long-arrow-alt-right:before {
  content: "\f30b";
}

.la-long-arrow-alt-up:before {
  content: "\f30c";
}

.la-low-vision:before {
  content: "\f2a8";
}

.la-luggage-cart:before {
  content: "\f59d";
}

.la-lyft:before {
  content: "\f3c3";
}

.la-magento:before {
  content: "\f3c4";
}

.la-magic:before {
  content: "\f0d0";
}

.la-magnet:before {
  content: "\f076";
}

.la-mail-bulk:before {
  content: "\f674";
}

.la-mailchimp:before {
  content: "\f59e";
}

.la-male:before {
  content: "\f183";
}

.la-mandalorian:before {
  content: "\f50f";
}

.la-map:before {
  content: "\f279";
}

.la-map-marked:before {
  content: "\f59f";
}

.la-map-marked-alt:before {
  content: "\f5a0";
}

.la-map-marker:before {
  content: "\f041";
}

.la-map-marker-alt:before {
  content: "\f3c5";
}

.la-map-pin:before {
  content: "\f276";
}

.la-map-signs:before {
  content: "\f277";
}

.la-markdown:before {
  content: "\f60f";
}

.la-marker:before {
  content: "\f5a1";
}

.la-mars:before {
  content: "\f222";
}

.la-mars-double:before {
  content: "\f227";
}

.la-mars-stroke:before {
  content: "\f229";
}

.la-mars-stroke-h:before {
  content: "\f22b";
}

.la-mars-stroke-v:before {
  content: "\f22a";
}

.la-mask:before {
  content: "\f6fa";
}

.la-mastodon:before {
  content: "\f4f6";
}

.la-maxcdn:before {
  content: "\f136";
}

.la-medal:before {
  content: "\f5a2";
}

.la-medapps:before {
  content: "\f3c6";
}

.la-medium:before {
  content: "\f23a";
}

.la-medium-m:before {
  content: "\f3c7";
}

.la-medkit:before {
  content: "\f0fa";
}

.la-medrt:before {
  content: "\f3c8";
}

.la-meetup:before {
  content: "\f2e0";
}

.la-megaport:before {
  content: "\f5a3";
}

.la-meh:before {
  content: "\f11a";
}

.la-meh-blank:before {
  content: "\f5a4";
}

.la-meh-rolling-eyes:before {
  content: "\f5a5";
}

.la-memory:before {
  content: "\f538";
}

.la-mendeley:before {
  content: "\f7b3";
}

.la-menorah:before {
  content: "\f676";
}

.la-mercury:before {
  content: "\f223";
}

.la-meteor:before {
  content: "\f753";
}

.la-microchip:before {
  content: "\f2db";
}

.la-microphone:before {
  content: "\f130";
}

.la-microphone-alt:before {
  content: "\f3c9";
}

.la-microphone-alt-slash:before {
  content: "\f539";
}

.la-microphone-slash:before {
  content: "\f131";
}

.la-microscope:before {
  content: "\f610";
}

.la-microsoft:before {
  content: "\f3ca";
}

.la-minus:before {
  content: "\f068";
}

.la-minus-circle:before {
  content: "\f056";
}

.la-minus-square:before {
  content: "\f146";
}

.la-mitten:before {
  content: "\f7b5";
}

.la-mix:before {
  content: "\f3cb";
}

.la-mixcloud:before {
  content: "\f289";
}

.la-mizuni:before {
  content: "\f3cc";
}

.la-mobile:before {
  content: "\f10b";
}

.la-mobile-alt:before {
  content: "\f3cd";
}

.la-modx:before {
  content: "\f285";
}

.la-monero:before {
  content: "\f3d0";
}

.la-money-bill:before {
  content: "\f0d6";
}

.la-money-bill-alt:before {
  content: "\f3d1";
}

.la-money-bill-wave:before {
  content: "\f53a";
}

.la-money-bill-wave-alt:before {
  content: "\f53b";
}

.la-money-check:before {
  content: "\f53c";
}

.la-money-check-alt:before {
  content: "\f53d";
}

.la-monument:before {
  content: "\f5a6";
}

.la-moon:before {
  content: "\f186";
}

.la-mortar-pestle:before {
  content: "\f5a7";
}

.la-mosque:before {
  content: "\f678";
}

.la-motorcycle:before {
  content: "\f21c";
}

.la-mountain:before {
  content: "\f6fc";
}

.la-mouse-pointer:before {
  content: "\f245";
}

.la-mug-hot:before {
  content: "\f7b6";
}

.la-music:before {
  content: "\f001";
}

.la-napster:before {
  content: "\f3d2";
}

.la-neos:before {
  content: "\f612";
}

.la-network-wired:before {
  content: "\f6ff";
}

.la-neuter:before {
  content: "\f22c";
}

.la-newspaper:before {
  content: "\f1ea";
}

.la-nimblr:before {
  content: "\f5a8";
}

.la-node:before {
  content: "\f419";
}

.la-node-js:before {
  content: "\f3d3";
}

.la-not-equal:before {
  content: "\f53e";
}

.la-notes-medical:before {
  content: "\f481";
}

.la-npm:before {
  content: "\f3d4";
}

.la-ns8:before {
  content: "\f3d5";
}

.la-nutritionix:before {
  content: "\f3d6";
}

.la-object-group:before {
  content: "\f247";
}

.la-object-ungroup:before {
  content: "\f248";
}

.la-odnoklassniki:before {
  content: "\f263";
}

.la-odnoklassniki-square:before {
  content: "\f264";
}

.la-oil-can:before {
  content: "\f613";
}

.la-old-republic:before {
  content: "\f510";
}

.la-om:before {
  content: "\f679";
}

.la-opencart:before {
  content: "\f23d";
}

.la-openid:before {
  content: "\f19b";
}

.la-opera:before {
  content: "\f26a";
}

.la-optin-monster:before {
  content: "\f23c";
}

.la-osi:before {
  content: "\f41a";
}

.la-otter:before {
  content: "\f700";
}

.la-outdent:before {
  content: "\f03b";
}

.la-page4:before {
  content: "\f3d7";
}

.la-pagelines:before {
  content: "\f18c";
}

.la-pager:before {
  content: "\f815";
}

.la-paint-brush:before {
  content: "\f1fc";
}

.la-paint-roller:before {
  content: "\f5aa";
}

.la-palette:before {
  content: "\f53f";
}

.la-palfed:before {
  content: "\f3d8";
}

.la-pallet:before {
  content: "\f482";
}

.la-paper-plane:before {
  content: "\f1d8";
}

.la-paperclip:before {
  content: "\f0c6";
}

.la-parachute-box:before {
  content: "\f4cd";
}

.la-paragraph:before {
  content: "\f1dd";
}

.la-parking:before {
  content: "\f540";
}

.la-passport:before {
  content: "\f5ab";
}

.la-pastafarianism:before {
  content: "\f67b";
}

.la-paste:before {
  content: "\f0ea";
}

.la-patreon:before {
  content: "\f3d9";
}

.la-pause:before {
  content: "\f04c";
}

.la-pause-circle:before {
  content: "\f28b";
}

.la-paw:before {
  content: "\f1b0";
}

.la-paypal:before {
  content: "\f1ed";
}

.la-peace:before {
  content: "\f67c";
}

.la-pen:before {
  content: "\f304";
}

.la-pen-alt:before {
  content: "\f305";
}

.la-pen-fancy:before {
  content: "\f5ac";
}

.la-pen-nib:before {
  content: "\f5ad";
}

.la-pen-square:before {
  content: "\f14b";
}

.la-pencil-alt:before {
  content: "\f303";
}

.la-pencil-ruler:before {
  content: "\f5ae";
}

.la-penny-arcade:before {
  content: "\f704";
}

.la-people-carry:before {
  content: "\f4ce";
}

.la-pepper-hot:before {
  content: "\f816";
}

.la-percent:before {
  content: "\f295";
}

.la-percentage:before {
  content: "\f541";
}

.la-periscope:before {
  content: "\f3da";
}

.la-person-booth:before {
  content: "\f756";
}

.la-phabricator:before {
  content: "\f3db";
}

.la-phoenix-framework:before {
  content: "\f3dc";
}

.la-phoenix-squadron:before {
  content: "\f511";
}

.la-phone:before {
  content: "\f095";
}

.la-phone-alt:before {
  content: "\f879";
}

.la-phone-slash:before {
  content: "\f3dd";
}

.la-phone-square:before {
  content: "\f098";
}

.la-phone-square-alt:before {
  content: "\f87b";
}

.la-phone-volume:before {
  content: "\f2a0";
}

.la-photo-video:before {
  content: "\f87c";
}

.la-php:before {
  content: "\f457";
}

.la-pied-piper:before {
  content: "\f2ae";
}

.la-pied-piper-alt:before {
  content: "\f1a8";
}

.la-pied-piper-hat:before {
  content: "\f4e5";
}

.la-pied-piper-pp:before {
  content: "\f1a7";
}

.la-piggy-bank:before {
  content: "\f4d3";
}

.la-pills:before {
  content: "\f484";
}

.la-pinterest:before {
  content: "\f0d2";
}

.la-pinterest-p:before {
  content: "\f231";
}

.la-pinterest-square:before {
  content: "\f0d3";
}

.la-pizza-slice:before {
  content: "\f818";
}

.la-place-of-worship:before {
  content: "\f67f";
}

.la-plane:before {
  content: "\f072";
}

.la-plane-arrival:before {
  content: "\f5af";
}

.la-plane-departure:before {
  content: "\f5b0";
}

.la-play:before {
  content: "\f04b";
}

.la-play-circle:before {
  content: "\f144";
}

.la-playstation:before {
  content: "\f3df";
}

.la-plug:before {
  content: "\f1e6";
}

.la-plus:before {
  content: "\f067";
}

.la-plus-circle:before {
  content: "\f055";
}

.la-plus-square:before {
  content: "\f0fe";
}

.la-podcast:before {
  content: "\f2ce";
}

.la-poll:before {
  content: "\f681";
}

.la-poll-h:before {
  content: "\f682";
}

.la-poo:before {
  content: "\f2fe";
}

.la-poo-storm:before {
  content: "\f75a";
}

.la-poop:before {
  content: "\f619";
}

.la-portrait:before {
  content: "\f3e0";
}

.la-pound-sign:before {
  content: "\f154";
}

.la-power-off:before {
  content: "\f011";
}

.la-pray:before {
  content: "\f683";
}

.la-praying-hands:before {
  content: "\f684";
}

.la-prescription:before {
  content: "\f5b1";
}

.la-prescription-bottle:before {
  content: "\f485";
}

.la-prescription-bottle-alt:before {
  content: "\f486";
}

.la-print:before {
  content: "\f02f";
}

.la-procedures:before {
  content: "\f487";
}

.la-product-hunt:before {
  content: "\f288";
}

.la-project-diagram:before {
  content: "\f542";
}

.la-pushed:before {
  content: "\f3e1";
}

.la-puzzle-piece:before {
  content: "\f12e";
}

.la-python:before {
  content: "\f3e2";
}

.la-qq:before {
  content: "\f1d6";
}

.la-qrcode:before {
  content: "\f029";
}

.la-question:before {
  content: "\f128";
}

.la-question-circle:before {
  content: "\f059";
}

.la-quidditch:before {
  content: "\f458";
}

.la-quinscape:before {
  content: "\f459";
}

.la-quora:before {
  content: "\f2c4";
}

.la-quote-left:before {
  content: "\f10d";
}

.la-quote-right:before {
  content: "\f10e";
}

.la-quran:before {
  content: "\f687";
}

.la-r-project:before {
  content: "\f4f7";
}

.la-radiation:before {
  content: "\f7b9";
}

.la-radiation-alt:before {
  content: "\f7ba";
}

.la-rainbow:before {
  content: "\f75b";
}

.la-random:before {
  content: "\f074";
}

.la-raspberry-pi:before {
  content: "\f7bb";
}

.la-ravelry:before {
  content: "\f2d9";
}

.la-react:before {
  content: "\f41b";
}

.la-reacteurope:before {
  content: "\f75d";
}

.la-readme:before {
  content: "\f4d5";
}

.la-rebel:before {
  content: "\f1d0";
}

.la-receipt:before {
  content: "\f543";
}

.la-recycle:before {
  content: "\f1b8";
}

.la-red-river:before {
  content: "\f3e3";
}

.la-reddit:before {
  content: "\f1a1";
}

.la-reddit-alien:before {
  content: "\f281";
}

.la-reddit-square:before {
  content: "\f1a2";
}

.la-redhat:before {
  content: "\f7bc";
}

.la-redo:before {
  content: "\f01e";
}

.la-redo-alt:before {
  content: "\f2f9";
}

.la-registered:before {
  content: "\f25d";
}

.la-remove-format:before {
  content: "\f87d";
}

.la-renren:before {
  content: "\f18b";
}

.la-reply:before {
  content: "\f3e5";
}

.la-reply-all:before {
  content: "\f122";
}

.la-replyd:before {
  content: "\f3e6";
}

.la-republican:before {
  content: "\f75e";
}

.la-researchgate:before {
  content: "\f4f8";
}

.la-resolving:before {
  content: "\f3e7";
}

.la-restroom:before {
  content: "\f7bd";
}

.la-retweet:before {
  content: "\f079";
}

.la-rev:before {
  content: "\f5b2";
}

.la-ribbon:before {
  content: "\f4d6";
}

.la-ring:before {
  content: "\f70b";
}

.la-road:before {
  content: "\f018";
}

.la-robot:before {
  content: "\f544";
}

.la-rocket:before {
  content: "\f135";
}

.la-rocketchat:before {
  content: "\f3e8";
}

.la-rockrms:before {
  content: "\f3e9";
}

.la-route:before {
  content: "\f4d7";
}

.la-rss:before {
  content: "\f09e";
}

.la-rss-square:before {
  content: "\f143";
}

.la-ruble-sign:before {
  content: "\f158";
}

.la-ruler:before {
  content: "\f545";
}

.la-ruler-combined:before {
  content: "\f546";
}

.la-ruler-horizontal:before {
  content: "\f547";
}

.la-ruler-vertical:before {
  content: "\f548";
}

.la-running:before {
  content: "\f70c";
}

.la-rupee-sign:before {
  content: "\f156";
}

.la-sad-cry:before {
  content: "\f5b3";
}

.la-sad-tear:before {
  content: "\f5b4";
}

.la-safari:before {
  content: "\f267";
}

.la-salesforce:before {
  content: "\f83b";
}

.la-sass:before {
  content: "\f41e";
}

.la-satellite:before {
  content: "\f7bf";
}

.la-satellite-dish:before {
  content: "\f7c0";
}

.la-save:before {
  content: "\f0c7";
}

.la-schlix:before {
  content: "\f3ea";
}

.la-school:before {
  content: "\f549";
}

.la-screwdriver:before {
  content: "\f54a";
}

.la-scribd:before {
  content: "\f28a";
}

.la-scroll:before {
  content: "\f70e";
}

.la-sd-card:before {
  content: "\f7c2";
}

.la-search:before {
  content: "\f002";
}

.la-search-dollar:before {
  content: "\f688";
}

.la-search-location:before {
  content: "\f689";
}

.la-search-minus:before {
  content: "\f010";
}

.la-search-plus:before {
  content: "\f00e";
}

.la-searchengin:before {
  content: "\f3eb";
}

.la-seedling:before {
  content: "\f4d8";
}

.la-sellcast:before {
  content: "\f2da";
}

.la-sellsy:before {
  content: "\f213";
}

.la-server:before {
  content: "\f233";
}

.la-servicestack:before {
  content: "\f3ec";
}

.la-shapes:before {
  content: "\f61f";
}

.la-share:before {
  content: "\f064";
}

.la-share-alt:before {
  content: "\f1e0";
}

.la-share-alt-square:before {
  content: "\f1e1";
}

.la-share-square:before {
  content: "\f14d";
}

.la-shekel-sign:before {
  content: "\f20b";
}

.la-shield-alt:before {
  content: "\f3ed";
}

.la-ship:before {
  content: "\f21a";
}

.la-shipping-fast:before {
  content: "\f48b";
}

.la-shirtsinbulk:before {
  content: "\f214";
}

.la-shoe-prints:before {
  content: "\f54b";
}

.la-shopping-bag:before {
  content: "\f290";
}

.la-shopping-basket:before {
  content: "\f291";
}

.la-shopping-cart:before {
  content: "\f07a";
}

.la-shopware:before {
  content: "\f5b5";
}

.la-shower:before {
  content: "\f2cc";
}

.la-shuttle-van:before {
  content: "\f5b6";
}

.la-sign:before {
  content: "\f4d9";
}

.la-sign-in-alt:before {
  content: "\f2f6";
}

.la-sign-language:before {
  content: "\f2a7";
}

.la-sign-out-alt:before {
  content: "\f2f5";
}

.la-signal:before {
  content: "\f012";
}

.la-signature:before {
  content: "\f5b7";
}

.la-sim-card:before {
  content: "\f7c4";
}

.la-simplybuilt:before {
  content: "\f215";
}

.la-sistrix:before {
  content: "\f3ee";
}

.la-sitemap:before {
  content: "\f0e8";
}

.la-sith:before {
  content: "\f512";
}

.la-skating:before {
  content: "\f7c5";
}

.la-sketch:before {
  content: "\f7c6";
}

.la-skiing:before {
  content: "\f7c9";
}

.la-skiing-nordic:before {
  content: "\f7ca";
}

.la-skull:before {
  content: "\f54c";
}

.la-skull-crossbones:before {
  content: "\f714";
}

.la-skyatlas:before {
  content: "\f216";
}

.la-skype:before {
  content: "\f17e";
}

.la-slack:before {
  content: "\f198";
}

.la-slack-hash:before {
  content: "\f3ef";
}

.la-slash:before {
  content: "\f715";
}

.la-sleigh:before {
  content: "\f7cc";
}

.la-sliders-h:before {
  content: "\f1de";
}

.la-slideshare:before {
  content: "\f1e7";
}

.la-smile:before {
  content: "\f118";
}

.la-smile-beam:before {
  content: "\f5b8";
}

.la-smile-wink:before {
  content: "\f4da";
}

.la-smog:before {
  content: "\f75f";
}

.la-smoking:before {
  content: "\f48d";
}

.la-smoking-ban:before {
  content: "\f54d";
}

.la-sms:before {
  content: "\f7cd";
}

.la-snapchat:before {
  content: "\f2ab";
}

.la-snapchat-ghost:before {
  content: "\f2ac";
}

.la-snapchat-square:before {
  content: "\f2ad";
}

.la-snowboarding:before {
  content: "\f7ce";
}

.la-snowflake:before {
  content: "\f2dc";
}

.la-snowman:before {
  content: "\f7d0";
}

.la-snowplow:before {
  content: "\f7d2";
}

.la-socks:before {
  content: "\f696";
}

.la-solar-panel:before {
  content: "\f5ba";
}

.la-sort:before {
  content: "\f0dc";
}

.la-sort-alpha-down:before {
  content: "\f15d";
}

.la-sort-alpha-down-alt:before {
  content: "\f881";
}

.la-sort-alpha-up:before {
  content: "\f15e";
}

.la-sort-alpha-up-alt:before {
  content: "\f882";
}

.la-sort-amount-down:before {
  content: "\f160";
}

.la-sort-amount-down-alt:before {
  content: "\f884";
}

.la-sort-amount-up:before {
  content: "\f161";
}

.la-sort-amount-up-alt:before {
  content: "\f885";
}

.la-sort-down:before {
  content: "\f0dd";
}

.la-sort-numeric-down:before {
  content: "\f162";
}

.la-sort-numeric-down-alt:before {
  content: "\f886";
}

.la-sort-numeric-up:before {
  content: "\f163";
}

.la-sort-numeric-up-alt:before {
  content: "\f887";
}

.la-sort-up:before {
  content: "\f0de";
}

.la-soundcloud:before {
  content: "\f1be";
}

.la-sourcetree:before {
  content: "\f7d3";
}

.la-spa:before {
  content: "\f5bb";
}

.la-space-shuttle:before {
  content: "\f197";
}

.la-speakap:before {
  content: "\f3f3";
}

.la-speaker-deck:before {
  content: "\f83c";
}

.la-spell-check:before {
  content: "\f891";
}

.la-spider:before {
  content: "\f717";
}

.la-spinner:before {
  content: "\f110";
}

.la-splotch:before {
  content: "\f5bc";
}

.la-spotify:before {
  content: "\f1bc";
}

.la-spray-can:before {
  content: "\f5bd";
}

.la-square:before {
  content: "\f0c8";
}

.la-square-full:before {
  content: "\f45c";
}

.la-square-root-alt:before {
  content: "\f698";
}

.la-squarespace:before {
  content: "\f5be";
}

.la-stack-exchange:before {
  content: "\f18d";
}

.la-stack-overflow:before {
  content: "\f16c";
}

.la-stackpath:before {
  content: "\f842";
}

.la-stamp:before {
  content: "\f5bf";
}

.la-star:before {
  content: "\f005";
}

.la-star-and-crescent:before {
  content: "\f699";
}

.la-star-half:before {
  content: "\f089";
}

.la-star-half-alt:before {
  content: "\f5c0";
}

.la-star-of-david:before {
  content: "\f69a";
}

.la-star-of-life:before {
  content: "\f621";
}

.la-staylinked:before {
  content: "\f3f5";
}

.la-steam:before {
  content: "\f1b6";
}

.la-steam-square:before {
  content: "\f1b7";
}

.la-steam-symbol:before {
  content: "\f3f6";
}

.la-step-backward:before {
  content: "\f048";
}

.la-step-forward:before {
  content: "\f051";
}

.la-stethoscope:before {
  content: "\f0f1";
}

.la-sticker-mule:before {
  content: "\f3f7";
}

.la-sticky-note:before {
  content: "\f249";
}

.la-stop:before {
  content: "\f04d";
}

.la-stop-circle:before {
  content: "\f28d";
}

.la-stopwatch:before {
  content: "\f2f2";
}

.la-store:before {
  content: "\f54e";
}

.la-store-alt:before {
  content: "\f54f";
}

.la-strava:before {
  content: "\f428";
}

.la-stream:before {
  content: "\f550";
}

.la-street-view:before {
  content: "\f21d";
}

.la-strikethrough:before {
  content: "\f0cc";
}

.la-stripe:before {
  content: "\f429";
}

.la-stripe-s:before {
  content: "\f42a";
}

.la-stroopwafel:before {
  content: "\f551";
}

.la-studiovinari:before {
  content: "\f3f8";
}

.la-stumbleupon:before {
  content: "\f1a4";
}

.la-stumbleupon-circle:before {
  content: "\f1a3";
}

.la-subscript:before {
  content: "\f12c";
}

.la-subway:before {
  content: "\f239";
}

.la-suitcase:before {
  content: "\f0f2";
}

.la-suitcase-rolling:before {
  content: "\f5c1";
}

.la-sun:before {
  content: "\f185";
}

.la-superpowers:before {
  content: "\f2dd";
}

.la-superscript:before {
  content: "\f12b";
}

.la-supple:before {
  content: "\f3f9";
}

.la-surprise:before {
  content: "\f5c2";
}

.la-suse:before {
  content: "\f7d6";
}

.la-swatchbook:before {
  content: "\f5c3";
}

.la-swimmer:before {
  content: "\f5c4";
}

.la-swimming-pool:before {
  content: "\f5c5";
}

.la-symfony:before {
  content: "\f83d";
}

.la-synagogue:before {
  content: "\f69b";
}

.la-sync:before {
  content: "\f021";
}

.la-sync-alt:before {
  content: "\f2f1";
}

.la-syringe:before {
  content: "\f48e";
}

.la-table:before {
  content: "\f0ce";
}

.la-table-tennis:before {
  content: "\f45d";
}

.la-tablet:before {
  content: "\f10a";
}

.la-tablet-alt:before {
  content: "\f3fa";
}

.la-tablets:before {
  content: "\f490";
}

.la-tachometer-alt:before {
  content: "\f3fd";
}

.la-tag:before {
  content: "\f02b";
}

.la-tags:before {
  content: "\f02c";
}

.la-tape:before {
  content: "\f4db";
}

.la-tasks:before {
  content: "\f0ae";
}

.la-taxi:before {
  content: "\f1ba";
}

.la-teamspeak:before {
  content: "\f4f9";
}

.la-teeth:before {
  content: "\f62e";
}

.la-teeth-open:before {
  content: "\f62f";
}

.la-telegram:before {
  content: "\f2c6";
}

.la-telegram-plane:before {
  content: "\f3fe";
}

.la-temperature-high:before {
  content: "\f769";
}

.la-temperature-low:before {
  content: "\f76b";
}

.la-tencent-weibo:before {
  content: "\f1d5";
}

.la-tenge:before {
  content: "\f7d7";
}

.la-terminal:before {
  content: "\f120";
}

.la-text-height:before {
  content: "\f034";
}

.la-text-width:before {
  content: "\f035";
}

.la-th:before {
  content: "\f00a";
}

.la-th-large:before {
  content: "\f009";
}

.la-th-list:before {
  content: "\f00b";
}

.la-the-red-yeti:before {
  content: "\f69d";
}

.la-theater-masks:before {
  content: "\f630";
}

.la-themeco:before {
  content: "\f5c6";
}

.la-themeisle:before {
  content: "\f2b2";
}

.la-thermometer:before {
  content: "\f491";
}

.la-thermometer-empty:before {
  content: "\f2cb";
}

.la-thermometer-full:before {
  content: "\f2c7";
}

.la-thermometer-half:before {
  content: "\f2c9";
}

.la-thermometer-quarter:before {
  content: "\f2ca";
}

.la-thermometer-three-quarters:before {
  content: "\f2c8";
}

.la-think-peaks:before {
  content: "\f731";
}

.la-thumbs-down:before {
  content: "\f165";
}

.la-thumbs-up:before {
  content: "\f164";
}

.la-thumbtack:before {
  content: "\f08d";
}

.la-ticket-alt:before {
  content: "\f3ff";
}

.la-times:before {
  content: "\f00d";
}

.la-times-circle:before {
  content: "\f057";
}

.la-tint:before {
  content: "\f043";
}

.la-tint-slash:before {
  content: "\f5c7";
}

.la-tired:before {
  content: "\f5c8";
}

.la-toggle-off:before {
  content: "\f204";
}

.la-toggle-on:before {
  content: "\f205";
}

.la-toilet:before {
  content: "\f7d8";
}

.la-toilet-paper:before {
  content: "\f71e";
}

.la-toolbox:before {
  content: "\f552";
}

.la-tools:before {
  content: "\f7d9";
}

.la-tooth:before {
  content: "\f5c9";
}

.la-torah:before {
  content: "\f6a0";
}

.la-torii-gate:before {
  content: "\f6a1";
}

.la-tractor:before {
  content: "\f722";
}

.la-trade-federation:before {
  content: "\f513";
}

.la-trademark:before {
  content: "\f25c";
}

.la-traffic-light:before {
  content: "\f637";
}

.la-train:before {
  content: "\f238";
}

.la-tram:before {
  content: "\f7da";
}

.la-transgender:before {
  content: "\f224";
}

.la-transgender-alt:before {
  content: "\f225";
}

.la-trash:before {
  content: "\f1f8";
}

.la-trash-alt:before {
  content: "\f2ed";
}

.la-trash-restore:before {
  content: "\f829";
}

.la-trash-restore-alt:before {
  content: "\f82a";
}

.la-tree:before {
  content: "\f1bb";
}

.la-trello:before {
  content: "\f181";
}

.la-tripadvisor:before {
  content: "\f262";
}

.la-trophy:before {
  content: "\f091";
}

.la-truck:before {
  content: "\f0d1";
}

.la-truck-loading:before {
  content: "\f4de";
}

.la-truck-monster:before {
  content: "\f63b";
}

.la-truck-moving:before {
  content: "\f4df";
}

.la-truck-pickup:before {
  content: "\f63c";
}

.la-tshirt:before {
  content: "\f553";
}

.la-tty:before {
  content: "\f1e4";
}

.la-tumblr:before {
  content: "\f173";
}

.la-tumblr-square:before {
  content: "\f174";
}

.la-tv:before {
  content: "\f26c";
}

.la-twitch:before {
  content: "\f1e8";
}

.la-twitter:before {
  content: "\f099";
}

.la-twitter-square:before {
  content: "\f081";
}

.la-typo3:before {
  content: "\f42b";
}

.la-uber:before {
  content: "\f402";
}

.la-ubuntu:before {
  content: "\f7df";
}

.la-uikit:before {
  content: "\f403";
}

.la-umbrella:before {
  content: "\f0e9";
}

.la-umbrella-beach:before {
  content: "\f5ca";
}

.la-underline:before {
  content: "\f0cd";
}

.la-undo:before {
  content: "\f0e2";
}

.la-undo-alt:before {
  content: "\f2ea";
}

.la-uniregistry:before {
  content: "\f404";
}

.la-universal-access:before {
  content: "\f29a";
}

.la-university:before {
  content: "\f19c";
}

.la-unlink:before {
  content: "\f127";
}

.la-unlock:before {
  content: "\f09c";
}

.la-unlock-alt:before {
  content: "\f13e";
}

.la-untappd:before {
  content: "\f405";
}

.la-upload:before {
  content: "\f093";
}

.la-ups:before {
  content: "\f7e0";
}

.la-usb:before {
  content: "\f287";
}

.la-user:before {
  content: "\f007";
}

.la-user-alt:before {
  content: "\f406";
}

.la-user-alt-slash:before {
  content: "\f4fa";
}

.la-user-astronaut:before {
  content: "\f4fb";
}

.la-user-check:before {
  content: "\f4fc";
}

.la-user-circle:before {
  content: "\f2bd";
}

.la-user-clock:before {
  content: "\f4fd";
}

.la-user-cog:before {
  content: "\f4fe";
}

.la-user-edit:before {
  content: "\f4ff";
}

.la-user-friends:before {
  content: "\f500";
}

.la-user-graduate:before {
  content: "\f501";
}

.la-user-injured:before {
  content: "\f728";
}

.la-user-lock:before {
  content: "\f502";
}

.la-user-md:before {
  content: "\f0f0";
}

.la-user-minus:before {
  content: "\f503";
}

.la-user-ninja:before {
  content: "\f504";
}

.la-user-nurse:before {
  content: "\f82f";
}

.la-user-plus:before {
  content: "\f234";
}

.la-user-secret:before {
  content: "\f21b";
}

.la-user-shield:before {
  content: "\f505";
}

.la-user-slash:before {
  content: "\f506";
}

.la-user-tag:before {
  content: "\f507";
}

.la-user-tie:before {
  content: "\f508";
}

.la-user-times:before {
  content: "\f235";
}

.la-users:before {
  content: "\f0c0";
}

.la-users-cog:before {
  content: "\f509";
}

.la-usps:before {
  content: "\f7e1";
}

.la-ussunnah:before {
  content: "\f407";
}

.la-utensil-spoon:before {
  content: "\f2e5";
}

.la-utensils:before {
  content: "\f2e7";
}

.la-vaadin:before {
  content: "\f408";
}

.la-vector-square:before {
  content: "\f5cb";
}

.la-venus:before {
  content: "\f221";
}

.la-venus-double:before {
  content: "\f226";
}

.la-venus-mars:before {
  content: "\f228";
}

.la-viacoin:before {
  content: "\f237";
}

.la-viadeo:before {
  content: "\f2a9";
}

.la-viadeo-square:before {
  content: "\f2aa";
}

.la-vial:before {
  content: "\f492";
}

.la-vials:before {
  content: "\f493";
}

.la-viber:before {
  content: "\f409";
}

.la-video:before {
  content: "\f03d";
}

.la-video-slash:before {
  content: "\f4e2";
}

.la-vihara:before {
  content: "\f6a7";
}

.la-vimeo:before {
  content: "\f40a";
}

.la-vimeo-square:before {
  content: "\f194";
}

.la-vimeo-v:before {
  content: "\f27d";
}

.la-vine:before {
  content: "\f1ca";
}

.la-vk:before {
  content: "\f189";
}

.la-vnv:before {
  content: "\f40b";
}

.la-voicemail:before {
  content: "\f897";
}

.la-volleyball-ball:before {
  content: "\f45f";
}

.la-volume-down:before {
  content: "\f027";
}

.la-volume-mute:before {
  content: "\f6a9";
}

.la-volume-off:before {
  content: "\f026";
}

.la-volume-up:before {
  content: "\f028";
}

.la-vote-yea:before {
  content: "\f772";
}

.la-vr-cardboard:before {
  content: "\f729";
}

.la-vuejs:before {
  content: "\f41f";
}

.la-walking:before {
  content: "\f554";
}

.la-wallet:before {
  content: "\f555";
}

.la-warehouse:before {
  content: "\f494";
}

.la-water:before {
  content: "\f773";
}

.la-wave-square:before {
  content: "\f83e";
}

.la-waze:before {
  content: "\f83f";
}

.la-weebly:before {
  content: "\f5cc";
}

.la-weibo:before {
  content: "\f18a";
}

.la-weight:before {
  content: "\f496";
}

.la-weight-hanging:before {
  content: "\f5cd";
}

.la-weixin:before {
  content: "\f1d7";
}

.la-whatsapp:before {
  content: "\f232";
}

.la-whatsapp-square:before {
  content: "\f40c";
}

.la-wheelchair:before {
  content: "\f193";
}

.la-whmcs:before {
  content: "\f40d";
}

.la-wifi:before {
  content: "\f1eb";
}

.la-wikipedia-w:before {
  content: "\f266";
}

.la-wind:before {
  content: "\f72e";
}

.la-window-close:before {
  content: "\f410";
}

.la-window-maximize:before {
  content: "\f2d0";
}

.la-window-minimize:before {
  content: "\f2d1";
}

.la-window-restore:before {
  content: "\f2d2";
}

.la-windows:before {
  content: "\f17a";
}

.la-wine-bottle:before {
  content: "\f72f";
}

.la-wine-glass:before {
  content: "\f4e3";
}

.la-wine-glass-alt:before {
  content: "\f5ce";
}

.la-wix:before {
  content: "\f5cf";
}

.la-wizards-of-the-coast:before {
  content: "\f730";
}

.la-wolf-pack-battalion:before {
  content: "\f514";
}

.la-won-sign:before {
  content: "\f159";
}

.la-wordpress:before {
  content: "\f19a";
}

.la-wordpress-simple:before {
  content: "\f411";
}

.la-wpbeginner:before {
  content: "\f297";
}

.la-wpexplorer:before {
  content: "\f2de";
}

.la-wpforms:before {
  content: "\f298";
}

.la-wpressr:before {
  content: "\f3e4";
}

.la-wrench:before {
  content: "\f0ad";
}

.la-x-ray:before {
  content: "\f497";
}

.la-xbox:before {
  content: "\f412";
}

.la-xing:before {
  content: "\f168";
}

.la-xing-square:before {
  content: "\f169";
}

.la-y-combinator:before {
  content: "\f23b";
}

.la-yahoo:before {
  content: "\f19e";
}

.la-yammer:before {
  content: "\f840";
}

.la-yandex:before {
  content: "\f413";
}

.la-yandex-international:before {
  content: "\f414";
}

.la-yarn:before {
  content: "\f7e3";
}

.la-yelp:before {
  content: "\f1e9";
}

.la-yen-sign:before {
  content: "\f157";
}

.la-yin-yang:before {
  content: "\f6ad";
}

.la-yoast:before {
  content: "\f2b1";
}

.la-youtube:before {
  content: "\f167";
}

.la-youtube-square:before {
  content: "\f431";
}

.la-zhihu:before {
  content: "\f63f";
}

.la-hat-cowboy:before {
  content: "\f8c0";
}

.la-hat-cowboy-side:before {
  content: "\f8c1";
}

.la-mdb:before {
  content: "\f8ca";
}

.la-mouse:before {
  content: "\f8cc";
}

.la-orcid:before {
  content: "\f8d2";
}

.la-record-vinyl:before {
  content: "\f8d9";
}

.la-swift:before {
  content: "\f8e1";
}

.la-umbraco:before {
  content: "\f8e8";
}

.la-buy-n-large:before {
  content: "\f8a6";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable.nice-select.open, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*! nouislider - 14.7.0 - 4/6/2021 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open:after {
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
.slick-lightbox .slick-loading .slick-list {
  background-color: transparent;
}
.slick-lightbox .slick-prev {
  left: 15px;
}
.slick-lightbox .slick-next {
  right: 15px;
}

.slick-lightbox-hide {
  opacity: 0;
}
.slick-lightbox-hide.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0;
}
.slick-lightbox-hide-init.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden;
}
.slick-lightbox-slick-item:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  display: block;
  text-align: center;
}

.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-caption {
  margin: 10px 0 0;
  color: white;
}

.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
}
.slick-lightbox-close:focus {
  outline: none;
}
.slick-lightbox-close:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "×";
}

.mega-menu > .multi-level .sub-menu > li:hover > a {
  color: #b0976d;
  color: var(--color-primary);
}
.mega-menu .dropdown {
  position: relative;
}
.mega-menu .dropdown:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
}
.mega-menu .dropdown > .sub-menu {
  position: absolute;
  width: 175px;
  padding: 12px 0 10px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: 150ms ease-in-out;
}
.mega-menu .dropdown > .sub-menu > li > a {
  font-size: 14px;
  line-height: 29px;
  position: relative;
  display: block;
  padding: 0 36px 0 19px;
  color: #dcdcdc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mega-menu .dropdown > .sub-menu > li > a > i {
  font-size: 12px;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}
.mega-menu > .fluid-menu {
  position: relative;
}
.mega-menu > .fluid-menu:hover > .fluid-menu-wrap {
  opacity: 1;
  visibility: visible;
}
.mega-menu > .fluid-menu > .fluid-menu-wrap {
  position: absolute;
  width: 720px;
  background: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: 150ms ease-in-out;
}
.mega-menu .fluid-menu-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 10px 0;
}
.mega-menu .fluid-menu-content .fluid-menu-list {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
  margin-bottom: 15px;
  padding: 0 15px;
}
.mega-menu .fluid-menu-content .fluid-menu-title {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 7px;
}
.mega-menu .fluid-menu-content .fluid-menu-title > a {
  color: #191919;
}
.mega-menu .fluid-menu-content .fluid-menu-title > a:hover {
  color: #b0976d;
  color: var(--color-primary);
}
.mega-menu .fluid-menu-content .fluid-sub-menu-list li a {
  font-size: 13px;
  line-height: 29px;
  max-width: 100%;
  display: inline-block;
  color: #dcdcdc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.mega-menu .fluid-menu-content .fluid-sub-menu-list li a:hover {
  color: #b0976d;
  color: var(--color-primary);
}

@keyframes menu-in-top {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes menu-in-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(15px);
  }
}
@keyframes menu-in-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(15px);
  }
}
@keyframes menu-in-left {
  0% {
    transform: translateX(-15px);
  }
  100% {
    transform: translateX(0);
  }
}
.breadcrumb {
  padding: 0;
  background: #ffffff;
}
.breadcrumb ul {
  margin-left: -11px;
}
.breadcrumb ul > li {
  font-size: 15px;
  line-height: 26px;
  position: relative;
  display: inline-block;
  padding: 0 10px;
}
.breadcrumb ul > li:after {
  position: absolute;
  content: "\f105";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 12px;
  right: -7px;
  top: 1px;
  color: #191919;
}
.breadcrumb ul > li:last-child:after {
  content: "";
}
.breadcrumb ul > li.active {
  color: #989898;
}
.breadcrumb ul > li.active:after {
  color: #989898;
}
.breadcrumb ul > li > a {
  color: #191919;
}
.breadcrumb ul > li > a:hover {
  color: #b0976d;
  color: var(--color-primary);
}

.badge {
  font-size: 14px;
  font-weight: 400;
  min-width: 120px;
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  border-radius: 20px;
}

.badge-success {
  color: #b0976d;
  background: #edf9f6;
}

.badge-danger {
  color: #e23e1d;
  background: #fdf0ed;
}

.badge-info {
  color: #247cc6;
  background: #eef5fb;
}

.badge-warning {
  color: #ffb136;
  background: #fff9ef;
}

.loading {
  position: relative;
  background: #0a0a0a;
  color: #fff !important;
  border-bottom: 1px solid #0f7404;
}

.btn-loading {
  color: transparent !important;
}
.btn-loading:after {
  position: absolute !important;
  content: "" !important;
  left: 50% !important;
  top: 50% !important;
  height: 16px !important;
  width: 16px !important;
  border: 2px solid #ffffff !important;
  border-radius: 50% !important;
  border-top-color: transparent !important;
  animation: loader-spin 800ms infinite linear !important;
}
.btn-loading:hover {
  background: linear-gradient(to right, #0f7404, #5cb917);
}

@keyframes loader-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes tab-loader {
  0% {
    left: 0;
    width: 20px;
  }
  50% {
    left: calc(100% - 20px);
    width: 20px;
  }
  100% {
    left: 0;
    width: 20px;
  }
}
.loader {
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.v-toast--fade-out {
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.v-toast--fade-in-down {
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.v-toast--fade-in-up {
  animation-name: fadeInUp;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 150ms ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-toast {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 1052;
  pointer-events: none;
}

.v-toast__item {
  display: inline-flex;
  align-items: center;
  animation-duration: 150ms;
  margin: 0.5em 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 0.25em;
  pointer-events: auto;
  opacity: 0.92;
  color: #fff;
  min-height: 3em;
  cursor: pointer;
}

.v-toast__item--success {
  background-color: #28a745;
}

.v-toast__item--info {
  background-color: #17a2b8;
}

.v-toast__item--warning {
  background-color: #ffc107;
}

.v-toast__item--error {
  background-color: #dc3545;
}

.v-toast__item--default {
  background-color: #343a40;
}

.v-toast__item.v-toast__item--top, .v-toast__item.v-toast__item--bottom {
  align-self: center;
}

.v-toast__item.v-toast__item--top-right, .v-toast__item.v-toast__item--bottom-right {
  align-self: flex-end;
}

.v-toast__item.v-toast__item--top-left, .v-toast__item.v-toast__item--bottom-left {
  align-self: flex-start;
}

.v-toast__text {
  margin: 0;
  padding: 0.5em 1em;
  word-break: break-word;
}

.v-toast__icon {
  display: none;
}

.v-toast.v-toast--top {
  flex-direction: column;
}

.v-toast.v-toast--bottom {
  flex-direction: column-reverse;
}

.v-toast.v-toast--custom-parent {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .v-toast {
    padding: 0;
    position: fixed !important;
  }
}
.notices .toast {
  max-width: 768px;
  opacity: 1;
  min-height: 3.5em;
}

.top_header-div {
  border-bottom: 1px solid #b0976d;
}

.top_header {
  padding: 10px 15px;
}
.top_header .top_header-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.top_header .top_header-icons > li {
  border-right: 1px solid #b0976d;
  display: inline-block;
  padding: 0 20px;
}
.top_header .top_header-icons > li:first-child {
  padding-left: 0;
}
.top_header .top_header-icons > li:last-child {
  padding-right: 0;
  border-right: none;
}
.top_header .top_header-icons > li > a {
  display: block;
  color: #191919;
}
.top_header .top_header-icons > li > a:hover {
  color: #b0976d;
  color: var(--color-primary);
}
.top_header .top_header-icons > li > a > i {
  margin-right: 5px;
  color: #b0976d;
  color: var(--color-primary);
}
.top_header .top_header-icons > li > i {
  margin-right: 5px;
  color: #b0976d;
  color: var(--color-primary);
}
.top_header .top_header-icons .nice-select {
  padding: 0 20px 0 0;
}
.top_header .top_header-icons .nice-select .list {
  left: -22px;
  min-width: 150px;
  margin-top: 11px;
  padding: 14px 0 11px;
}
.top_header .top_header-icons .nice-select .option {
  padding: 0 20px;
}

@media screen and (max-width: 991px) {
  .top_header > .row {
    flex-direction: column;
  }
  .top_header .top_header-left {
    margin-bottom: 10px;
    text-align: center;
  }
}
.header-search {
  position: relative;
  transform: scale(0.9);
  margin-right: 25px;
}
.sidebar-menu-wrap {
  position: fixed;
  left: -150px;
  top: 0;
  bottom: 0;
  width: 270px;
  background: #ffffff;
  box-shadow: 2.5px 4.33px 40px 5px rgba(12, 31, 46, 0.1);
  opacity: 0;
  visibility: hidden;
  z-index: 200;
  transition: 200ms ease-in-out;
}
.sidebar-menu-wrap.active {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.sidebar-menu-wrap .sidebar-menu-header {
  display: flex;
  padding: 15px 26px;
  align-items: center;
  justify-content: space-between;
  background: #b0976d;
  background: var(--color-primary);
}
.sidebar-menu-wrap .sidebar-menu-header > h4 {
  font-weight: 400;
  color: #ffffff;
}
.sidebar-menu-wrap .sidebar-menu-header .sidebar-menu-close {
  display: flex;
  cursor: pointer;
  opacity: 0.8;
  transition: 150ms ease-in-out;
}
.sidebar-menu-wrap .sidebar-menu-header .sidebar-menu-close:hover {
  opacity: 1;
}
.sidebar-menu-wrap .sidebar-menu-header .sidebar-menu-close > i {
  font-size: 18px;
  color: #ffffff;
}
.sidebar-menu-wrap .sidebar-menu-tab {
  border-bottom: none;
}
.sidebar-menu-wrap .sidebar-menu-tab .nav-item {
  width: 50%;
}
.sidebar-menu-wrap .sidebar-menu-tab.nav-tabs .nav-link {
  padding: 8px;
  color: #989898;
  text-align: center;
  background: #f2f4f5;
  border: none;
  border-radius: 0;
}
.sidebar-menu-wrap .sidebar-menu-tab.nav-tabs .nav-link.active {
  font-weight: 400;
  color: #191919;
  background: #ffffff;
  cursor: default;
}
.sidebar-menu-wrap .sidebar-menu-tab.nav-tabs .nav-link:after {
  content: none;
}
.sidebar-menu-wrap > .tab-content {
  position: absolute;
  left: 0;
  top: 100px;
  right: 0;
  bottom: 0;
}

.sidebar-menu {
  margin: 15px 0;
}
.sidebar-menu > li {
  cursor: pointer;
}
.sidebar-menu > li:hover > .menu-item {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu > li:hover > .menu-item > .menu-item-icon {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu > li:hover > i {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu > li.active .menu-item {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu > li.active .menu-item:after {
  opacity: 1;
  visibility: visible;
}
.sidebar-menu > li.active .menu-item > .menu-item-icon {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu > li.active > i {
  transform: rotate(90deg);
}
.sidebar-menu > li > .menu-item:after {
  position: absolute;
  content: "";
  left: 0;
  top: 16px;
  height: 15px;
  width: 7px;
  background: #b0976d;
  background: var(--color-primary);
  opacity: 0;
  visibility: hidden;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: 150ms ease-in-out;
}
.sidebar-menu > li > .menu-item > .menu-item-icon {
  font-size: 16px;
  color: #dcdcdc;
  margin: 1px 10px 0 0;
  transition: 150ms ease-in-out;
}
.sidebar-menu > li > ul > li > a {
  max-width: 160px;
}
.sidebar-menu > li > ul > li > ul > li > a {
  max-width: 155px;
}
.sidebar-menu li:hover > a,
.sidebar-menu li:hover > i {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu li a {
  line-height: 45px;
  max-width: 170px;
  display: inline-block;
  margin-left: 24px;
  color: #191919;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.sidebar-menu li > i {
  font-size: 12px;
  position: absolute;
  top: 18px;
  right: 30px;
  color: #dcdcdc;
  float: right;
  transition: 150ms ease-in-out;
}
.sidebar-menu li ul {
  display: none;
  margin-left: 10px;
}
.sidebar-menu .dropdown {
  position: relative;
}
.sidebar-menu .dropdown.active > a {
  color: #b0976d;
  color: var(--color-primary);
}
.sidebar-menu .dropdown.active > i {
  color: #b0976d;
  color: var(--color-primary);
  transform: rotate(90deg);
}

.navigation-inner {
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
}
.navigation-inner .navbar {
  padding: 0;
  z-index: 2;
}
.navigation-inner .navbar .navbar-nav {
  margin: 0 30px;
}
.navigation-inner .navigation-text {
  margin-left: auto;
  margin-right: 25px;
  color: #ffb136;
}

.category-nav {
  position: relative;
  width: 262px;
  min-width: 262px;
  padding: 4px 0 4px 4px;
  align-items: center;
  align-self: stretch;
}
.category-nav.show .category-nav-inner {
  cursor: default;
}
.category-nav.show .category-dropdown-wrap {
  display: block;
}
.category-nav .category-nav-inner {
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  display: flex;
  color: #ffffff;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  background: #b0976d;
  background: var(--color-primary);
  border-radius: 2px 0 0 2px;
  cursor: pointer;
}
.category-nav .category-nav-inner:after {
  display: none;
}
.category-nav .category-nav-inner > i {
  font-size: 16px;
}
.category-nav .category-dropdown-wrap {
  position: relative;
  left: -1px;
  top: calc(100% + 31px);
  display: none;
  width: 263px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-top: none;
  border-bottom: none;
  border-radius: 2px;
  z-index: 4;
}
.category-nav .category-dropdown-wrap:before, .category-nav .category-dropdown-wrap:after {
  position: absolute;
  content: "";
  left: 0;
  height: 1px;
  width: 100%;
  background: #e1e1e1;
}
.category-nav .category-dropdown-wrap:before {
  top: 0;
}
.category-nav .category-dropdown-wrap:after {
  bottom: 0;
}
.category-nav .category-dropdown-wrap.show {
  display: block;
}
.category-nav .category-dropdown-wrap .category-dropdown {
  background: #b0976d;
}

.vertical-megamenu {
  position: static;
  padding: 15px 0;
}
.vertical-megamenu > li.hide {
  display: none;
}
.vertical-megamenu > li.more-categories > .menu-item {
  font-weight: 500;
}
.vertical-megamenu > li:hover > .menu-item {
  font-weight: 500;
  color: #b0976d;
  color: var(--color-primary);
}
.vertical-megamenu > li:hover > .menu-item:after {
  opacity: 1;
  visibility: visible;
}
.vertical-megamenu > li:hover > .menu-item > .menu-item-icon {
  color: #b0976d;
  color: var(--color-primary);
}
.vertical-megamenu > li:hover > .menu-item > i {
  color: #b0976d;
  color: var(--color-primary);
}
.vertical-megamenu > li > .menu-item {
  position: relative;
  line-height: 37px;
  display: block;
  padding: 0 14px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: none;
  font-size: 15px;
}
.vertical-megamenu > li > .menu-item:after {
  position: absolute;
  content: "";
  left: 0;
  top: 15px;
  height: 15px;
  width: 7px;
  background: #b0976d;
  background: var(--color-primary);
  opacity: 0;
  visibility: hidden;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: 150ms ease-in-out;
}
.vertical-megamenu > li > .menu-item > .menu-item-icon {
  font-size: 16px;
  display: inline-flex;
  margin: 15px 10px 0 0;
  color: #ffffff;
  vertical-align: top;
  transition: 150ms ease-in-out;
}
.vertical-megamenu > li > .menu-item > i {
  font-size: 12px;
  position: absolute;
  top: 17px;
  right: 25px;
  color: #dcdcdc;
  transition: 150ms ease-in-out;
}
.vertical-megamenu.mega-menu .dropdown:hover > .sub-menu {
  animation: menu-in-left 250ms ease;
}
.vertical-megamenu.mega-menu .dropdown > .sub-menu {
  right: -175px;
  top: 0;
  animation: menu-in-right 250ms ease;
}
.vertical-megamenu.mega-menu > .fluid-menu:hover > .fluid-menu-wrap {
  animation: menu-in-left 250ms ease;
}
.vertical-megamenu.mega-menu > .fluid-menu > .fluid-menu-wrap {
  top: 0;
  right: -720px;
  animation: menu-in-right 250ms ease;
}

.horizontal-megamenu {
  position: relative;
  flex-wrap: wrap;
}
.horizontal-megamenu.mega-menu > .fluid-menu {
  position: relative;
}
.horizontal-megamenu > .nav-item:hover > .nav-link {
  font-weight: 500;
  color: #b0976d;
  color: var(--color-primary);
}
.horizontal-megamenu > .nav-item:hover > .nav-link > i {
  right: 12px;
  color: #b0976d;
  color: var(--color-primary);
}
.horizontal-megamenu > .nav-item.multi-level > .nav-link, .horizontal-megamenu > .nav-item.fluid-menu > .nav-link {
  padding-right: 35px;
}
.horizontal-megamenu > .nav-item > .nav-link {
  line-height: 36px;
  position: relative;
  margin-bottom: -1px;
  padding: 12px 15px 12px;
  color: #191919;
  white-space: nowrap;
  transition: none;
}
.horizontal-megamenu > .nav-item > .nav-link:after {
  content: attr(data-text);
  font-weight: 500;
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.horizontal-megamenu > .nav-item > .nav-link > i {
  position: absolute;
  top: 50%;
  right: 15px;
  color: #dcdcdc;
  transform: translateY(-50%) rotate(90deg);
}
.horizontal-megamenu.mega-menu > .dropdown.multi-level > .sub-menu {
  left: 0;
  top: 59px;
}
.horizontal-megamenu.mega-menu .dropdown:hover > .sub-menu {
  animation: menu-in-top 250ms ease;
}
.horizontal-megamenu.mega-menu .dropdown > .sub-menu {
  left: 100%;
  top: 0;
  animation: menu-in-bottom 250ms ease;
}
.horizontal-megamenu.mega-menu > .fluid-menu:hover > .fluid-menu-wrap {
  animation: menu-in-top 250ms ease;
}
.horizontal-megamenu.mega-menu > .fluid-menu > .fluid-menu-wrap {
  top: 59px;
  left: 0;
  animation: menu-in-bottom 250ms ease;
}

.rtl .horizontal-megamenu > .nav-item:hover > .nav-link > i {
  left: 15px /*rtl:ignore*/;
}

@media screen and (max-width: 1400px) {
  .category-nav {
    width: 245px;
    min-width: 245px;
  }
  .category-nav .category-dropdown-wrap {
    width: 246px;
  }
}
@media screen and (max-width: 1300px) {
  .navigation-inner .navigation-text {
    display: none;
  }
}
@media screen and (max-width: 1047px) {
  .vertical-megamenu.mega-menu > .fluid-menu > .fluid-menu-wrap {
    right: -696px;
    width: 696px;
  }
}
.product-details-top {
  display: flex;
  margin-bottom: 20px;
}
.product-details-top .right-sidebar {
  width: 285px;
  min-width: 285px;
  padding-left: 15px;
}
.product-details-top .right-sidebar .feature-list {
  flex-direction: column;
  margin-top: 0;
}
.product-details-top .right-sidebar .feature-list .single-feature {
  margin-bottom: 20px;
  justify-content: flex-start;
}
.product-details-top .right-sidebar .feature-list .single-feature:last-child {
  margin-bottom: 0;
}

.product-details-top-inner {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1350px) {
  .product-details-top .right-sidebar {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .product-details-top-inner {
    flex-direction: column;
  }
}
.product_images {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  width: 530px;
}

.more-images-div {
  width: 80px;
  min-width: 80px;
  margin-top: 8px;
}
.more-images-div .more-image {
  display: flex;
  height: 80px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transition: 150ms ease-in-out;
}
.more-images-div .more-image.slick-current {
  border-color: #b0976d;
  border-color: var(--color-primary);
}
.more-images-div .more-image > img {
  max-height: 100%;
  max-width: 100%;
}
.more-images-div .more-image .image-placeholder {
  height: 35px;
  width: 35px;
}

.product-picture-div {
  position: relative;
  width: 100%;
  min-width: 0;
  margin-left: 16px;
  flex-grow: 1;
  border: 1px solid #ebebeb;
  border-radius: 2px;
}
.product-picture-div .product-picture.slick-initialized .product-picture-inner:not(:first-child) {
  display: block;
}
.product-picture-div .product-picture .product-picture-inner:not(:first-child) {
  display: none;
}
.product-picture-div .product-picture-slide {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 100%;
  background: #ffffff;
  cursor: crosshair;
}
.product-picture-div .product-picture-slide:hover > img:not(.zoomImg) {
  opacity: 0 !important;
}
.product-picture-div .product-picture-slide:hover .zoomImg {
  opacity: 1 !important;
}
.product-picture-div .product-picture-slide > img:not(.zoomImg) {
  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}
.product-picture-div .product-picture-slide .image-placeholder {
  width: 130px;
}
.product-picture-div .product-picture-slide .image-placeholder ~ .zoomImg {
  display: none !important;
}
.product-picture-div .btn-gallery-trigger {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  transform: rotateY(180deg);
}
.product-picture-div .btn-gallery-trigger > i {
  font-size: 20px;
  transition: 150ms ease-in-out;
}
.product-picture-div .btn-gallery-trigger:hover > i {
  color: #b0976d;
  color: var(--color-primary);
}

@media screen and (max-width: 1600px) {
  .product_images {
    width: 500px;
  }
  .more-images-div {
    width: 70px;
    min-width: 70px;
  }
  .more-images-div .more-image {
    height: 70px;
  }
}
@media screen and (max-width: 991px) {
  .product-picture-div .product-picture-slide {
    cursor: zoom-in;
  }
  .product-picture-div .product-picture-slide:hover > img:not(.zoomImg) {
    opacity: 1 !important;
  }
  .product-picture-div .product-picture-slide:hover .zoomImg {
    opacity: 0 !important;
  }
  .product-picture-div .zoomImg {
    opacity: 0 !important;
  }
  .product-picture-div .btn-gallery-trigger {
    display: none;
  }
}
@media screen and (min-width: 577px) {
  .more-images-div .slick-prev,
  .more-images-div .slick-next {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .more-images-div .slick-prev {
    top: -4px;
  }
  .more-images-div .slick-prev:before {
    content: "\f106";
  }
  .more-images-div .slick-next {
    left: 0;
    top: auto;
    bottom: -14px;
    transform: translate(0, 0);
  }
  .more-images-div .slick-next:before {
    content: "\f107";
  }
}
@media screen and (max-width: 576px) {
  .product_images {
    width: 100%;
    flex-direction: column-reverse;
  }
  .rtl .more-images-div .slick-track {
    right: 2px /*rtl:ignore*/;
  }
  .more-images-div {
    display: flex;
    width: calc(100% - 32px);
    min-width: 0;
    margin: 16px auto 0;
  }
  .more-images-div .slick-prev {
    left: -22px;
  }
  .more-images-div .slick-next {
    right: -22px;
  }
  .more-images-div .more-image {
    width: 70px;
    min-width: 70px;
    margin: 0 8px;
  }
  .product-picture-div {
    margin-left: 0;
  }
  .product-picture-div .product-picture-slide {
    padding-bottom: 90%;
  }
}
.availability {
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
}
.availability.in-stock {
  color: #b0976d;
}
.availability.out-of-stock {
  color: #fd6602;
}
.availability + .product_details_top {
  margin-top: -2px;
}

.product-informations {
  width: calc(100% - 600px);
  padding: 0 15px 0 30px;
}
.product-informations .details-info-top {
  padding-bottom: 17px;
}
.product-informations .product-name {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}
.product-informations .product-rating {
  font-size: 16px;
  margin-bottom: 9px;
}
.product-informations .product-rating .rating-count {
  font-size: 14px;
  margin-top: 3px;
}
.product-informations .product-rating + .big_desc {
  margin-top: 10px;
}
.product-informations .product-rating + .product_details_top {
  margin-top: 8px;
}
.product-informations .big_desc {
  margin-bottom: 12px;
  color: #dcdcdc;
}
.product-informations .product_details_top .btn {
  padding: 0;
  color: #dcdcdc;
}
.product-informations .product_details_top .btn:hover {
  color: #b0976d;
  color: var(--color-primary);
}
.product-informations .product_details_top .btn:hover > i {
  color: #dcdcdc;
}
.product-informations .product_details_top .btn > i {
  margin-right: 4px;
}
.product-informations .product_details_top .btn-favourites {
  margin-right: 25px;
}
.product-informations .product_details_top .btn-favourites,
.product-informations .product_details_top .btn-compare {
  font-weight: 500;
}
.product-informations .product_details_top .btn-favourites.added:hover > i,
.product-informations .product_details_top .btn-compare.added:hover > i {
  color: #b0976d;
  color: var(--color-primary);
}
.product-informations .details-info-middle {
  padding: 22px 0 25px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.product-informations .product-price {
  font-size: 24px;
  padding-bottom: 18px;
}
.product-informations .product-price .previous-price {
  font-size: 14px;
  margin-top: 3px;
}
.product-informations .product-variants {
  padding-bottom: 10px;
}
.product-informations .product-variants .form-group .extra-price {
  font-weight: 500;
  color: #dcdcdc;
}
.product-informations .product-variants .form-group .form-custom-radio .extra-price {
  margin-left: 4px;
}
.product-informations .product-variants .variant-check {
  margin: -1px 0 7px;
}
.product-informations .product-variants .variant-check .error-message {
  margin: -7px 0 8px;
}
.product-informations .product-variants .variant-radio {
  margin: -2px 0 8px;
}
.product-informations .product-variants .variant-radio .error-message {
  margin: -6px 0 7px;
}
.product-informations .product-variants .variant-input,
.product-informations .product-variants .variant-select {
  margin-bottom: 20px;
}
.product-informations .product-variants .variant-input label,
.product-informations .product-variants .variant-select label {
  padding-top: 12px;
}
.product-informations .product-variants .variant-input .error-message,
.product-informations .product-variants .variant-select .error-message {
  margin: 6px 0 -5px;
}
.product-informations .product-variants .variant-custom-selection {
  margin-bottom: 10px;
}
.product-informations .product-variants .variant-custom-selection label {
  padding-top: 6px;
}
.product-informations .product-variants .variant-custom-selection .error-message {
  margin: -4px 0 5px;
}
.product-informations .product-variants .custom-selection {
  display: inline-flex;
  flex-wrap: wrap;
}
.product-informations .product-variants .custom-selection > li {
  font-size: 13px;
  height: 32px;
  min-width: 42px;
  display: flex;
  margin: 0 10px 10px 0;
  padding: 6px 10px;
  color: #dcdcdc;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  transition: 150ms ease-in-out;
  cursor: pointer;
}
.product-informations .product-variants .custom-selection > li:last-child {
  margin-right: 0;
}
.product-informations .product-variants .custom-selection > li:hover {
  border-color: #b0976d;
  border-color: var(--color-primary);
}
.product-informations .product-variants .custom-selection > li.active {
  cursor: default;
  color: #b0976d;
  color: var(--color-primary);
  border-color: #b0976d;
  border-color: var(--color-primary);
}
.product-informations .product-variants .custom-selection > li.disabled {
  opacity: 0.65;
  cursor: default;
}
.product-informations .product-variants .custom-selection > li.disabled:hover {
  border-color: #ebebeb;
}
.product-informations .details-info-middle-actions {
  display: flex;
}
.product-informations .number-picker {
  display: inline-flex;
  align-items: center;
}
.product-informations .number-picker label {
  font-weight: 500;
  margin-bottom: 0;
}
.product-informations .number-picker .input-group-quantity {
  position: relative;
  margin-left: 20px;
}
.product-informations .number-picker .input-group-quantity .input-quantity {
  width: 100px;
  padding: 10px 39px 10px 10px;
  text-align: center;
}
.product-informations .number-picker .input-group-quantity .input-quantity:focus {
  outline: 0;
  border-color: #ebebeb;
}
.product-informations .number-picker .input-group-quantity .btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}
.product-informations .number-picker .input-group-quantity .btn-wrapper .btn-number {
  position: absolute;
  right: 0;
  width: 30px;
  padding: 0;
  line-height: 18px;
  background: none;
  border: 1px solid #ebebeb;
  border-radius: 0;
}
.product-informations .number-picker .input-group-quantity .btn-wrapper .btn-number:hover {
  color: #b0976d;
  color: var(--color-primary);
}
.product-informations .number-picker .input-group-quantity .btn-wrapper .btn-number[disabled]:hover {
  color: #191919;
}
.product-informations .number-picker .input-group-quantity .btn-wrapper .btn-plus {
  top: 0;
  height: 24px;
  border-top-right-radius: 2px;
}
.product-informations .number-picker .input-group-quantity .btn-wrapper .btn-minus {
  top: 23px;
  height: 22px;
  border-bottom-right-radius: 2px;
}
.product-informations .btn-add-to-cart {
  margin-left: 20px;
  padding: 8px 30px;
}
.product-informations .btn-add-to-cart > i {
  margin-right: 6px;
}
.product-informations .details-info-bottom {
  padding: 10px 0;
}
.product-informations .additional-info {
  display: flex;
  flex-direction: column;
  margin: 0 0 -6px -12px;
  padding: 5px 0;
}
.product-informations .additional-info li {
  margin-bottom: 4px;
  padding: 0 12px;
}
.product-informations .additional-info li:last-child {
  margin-bottom: 0;
}
.product-informations .additional-info li label {
  margin-bottom: 0;
}
.product-informations .additional-info li a {
  color: #b0976d;
  color: var(--color-primary);
}
.product-informations .additional-info li a:hover {
  color: #0055b8;
  color: var(--color-primary-hover);
}
.product-informations .additional-info .sku {
  color: #b0976d;
  color: var(--color-primary);
}
.product-informations .additional-info .sku label {
  color: #191919;
}
.product-informations .social-share {
  display: flex;
  align-items: center;
}
.product-informations .social-share label {
  margin-bottom: 0;
}
.product-informations .social-share .social-links {
  margin-left: 16px;
}

@media screen and (max-width: 1600px) {
  .product-informations {
    width: calc(100% - 500px);
  }
}
@media screen and (max-width: 991px) {
  .product-informations {
    width: 100%;
    margin-top: 34px;
    padding: 0;
  }
  .product-informations .product-variants {
    margin-top: -5px;
  }
  .product-informations .product-variants .variant-custom-selection label {
    padding-top: 0;
  }
  .product-informations .product-variants .variant-check,
  .product-informations .product-variants .variant-radio {
    margin-top: 0;
  }
  .product-informations .product-variants .variant-check label,
  .product-informations .product-variants .variant-radio label {
    margin-bottom: 8px;
  }
  .product-informations .product-variants .variant-input label,
  .product-informations .product-variants .variant-select label {
    padding-top: 0;
  }
}
@media screen and (max-width: 420px) {
  .product-informations .details-info-middle-actions {
    flex-direction: column;
  }
  .product-informations .btn-add-to-cart {
    margin: 20px 0 0 0;
    align-self: flex-start;
  }
}
.product-details-bottom {
  display: flex;
}
.product-details-bottom .left-sidebar {
  width: 285px;
  min-width: 285px;
  padding-right: 15px;
}
.product-details-bottom .left-sidebar .vertical-products {
  margin-top: 14px;
}
.product-details-bottom .left-sidebar .vertical-products + .banner {
  margin-top: 45px;
}
.product-details-bottom .left-sidebar .banner {
  margin-top: 20px;
}
.product-details-bottom .product-details-bottom-inner {
  width: calc(100% - 282px);
  padding-left: 15px;
}

.product-details-tab > .tabs {
  justify-content: center;
  border-bottom: 1px solid #ebebeb;
}
.product-details-tab > .tabs .nav-item {
  margin: 0 26px -1px;
}
.product-details-tab > .tabs .nav-link {
  font-size: 20px;
  font-weight: 500;
  padding: 15px 3px;
  color: #191919;
}

@media screen and (max-width: 991px) {
  .product-details-bottom .left-sidebar {
    width: 100%;
    min-width: 0;
  }
  .product-details-bottom .left-sidebar .vertical-products {
    margin-top: 44px;
  }
  .product-details-bottom .product-details-bottom-inner {
    width: 100%;
    padding-left: 0;
  }
  .product-details-bottom .product-details-tab {
    margin-bottom: -15px;
  }
}
.description {
  padding-top: 46px;
}
.description h1, .description h2, .description h3, .description h4, .description h5, .description h6 {
  margin-bottom: 14px;
}
.description p {
  line-height: 24px;
  color: #dcdcdc;
}
.description ul, .description ol {
  padding-left: 16px;
  overflow: hidden;
}
.description ul li, .description ol li {
  margin-bottom: 9px;
  color: #dcdcdc;
}
.description b, .description strong, .description em {
  font-weight: 500;
}
.description a {
  color: #b0976d;
  color: var(--color-primary);
}
.description a:hover {
  color: #0055b8;
  color: var(--color-primary-hover);
}
.description img {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.description iframe {
  border: none;
  max-width: 100%;
}
.description table {
  width: 100%;
}
.description th {
  font-weight: 500;
  border-bottom: 2px solid var(--color-primary);
}
.description td {
  vertical-align: top;
  padding: 8px;
  border: 1px solid #cccccc;
}

@media screen and (max-width: 991px) {
  .description {
    padding-top: 36px;
  }
}
@media screen and (max-width: 767px) {
  .description img {
    width: 100%;
  }
}
.specification {
  padding: 0 30px;
}
.specification .specification-inner {
  border: 1px solid #ebebeb;
  border-radius: 2px;
}
.specification .title {
  padding: 15px 30px;
  background: #f9f9f9;
}

.specification-list {
  padding: 35px 30px 34px 30px;
}
.specification-list li {
  display: flex;
  margin-bottom: 18px;
}
.specification-list li:last-child {
  margin-bottom: 0;
}
.specification-list label {
  font-weight: 500;
  line-height: 23px;
  width: 190px;
  min-width: 190px;
  margin-bottom: 0;
}
.specification-list span {
  line-height: 23px;
  color: #dcdcdc;
}

@media screen and (max-width: 991px) {
  .specification {
    padding: 41px 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .specification-list li {
    flex-direction: column;
  }
  .specification-list label {
    margin-bottom: 3px;
  }
}
.reviews {
  padding-top: 45px;
}
.reviews .pagination {
  margin-top: 11px;
}

.review-form > h4 {
  margin-bottom: 28px;
}
.review-form .rating-input {
  font-size: 20px;
  line-height: 20px;
  position: relative;
  display: flex;
  margin: -1px 0 -2px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.review-form .rating-input label {
  margin-bottom: 0;
  color: #ebebeb;
  cursor: pointer;
  transition: 150ms ease-in-out;
}
.review-form .rating-input label:hover, .review-form .rating-input label:focus {
  color: #ffb136;
}
.review-form .rating-input label:hover ~ label, .review-form .rating-input label:focus ~ label {
  color: #ffb136;
}
.review-form .rating-input input[type=radio] {
  display: none;
}
.review-form .rating-input input[type=radio]:checked ~ label {
  color: #ffb136;
}
.user-review-wrap {
  margin-top: 4px;
}
.user-review-wrap.loading:after {
  border-width: 2px;
}
.user-review-wrap .empty-message {
  display: flex;
  padding: 35px 0 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.user-review-wrap .empty-message svg {
  height: 80px;
  width: 80px;
  margin-bottom: 16px;
}
.user-review-wrap .empty-message svg path {
  stroke-width: 3px;
  fill: #b0976d;
  fill: var(--color-primary);
  stroke: #b0976d;
  stroke: var(--color-primary);
}

.user-review {
  margin-bottom: 27px;
}
.user-review:nth-child(4) {
  margin-bottom: 0;
}
.user-review:nth-child(n+5) {
  display: none;
}
.user-review .reviewer-name {
  font-size: 16px;
  margin-bottom: 6px;
}
.user-review .product-rating {
  margin-bottom: 11px;
}
.user-review .reviewer-message {
  color: #dcdcdc;
  margin-bottom: 7px;
}
.user-review .review-date,
.user-review .review-approval {
  font-size: 14px;
  color: #989898;
}

@media screen and (max-width: 1199px) {
  .user-review-wrap {
    margin: 48px 0 8px;
  }
}
@media screen and (max-width: 991px) {
  .reviews {
    padding-top: 35px;
  }
}
.product-details-wrap {
  margin-top: 50px;
}

.filter-section-wrap {
  padding-left: 23px;
  background: #ffffff;
}
.filter-section-wrap .filter-section-header > i {
  display: none;
}
.filter-section-wrap .filter-section-inner.custom-scrollbar {
  overflow-y: visible;
}

.filter-section.tuning .filter-checkbox .form-check {
  float: left;
  padding-left: 10px;
}
.filter-section.tuning .btn-show-more {
  float: left;
  clear: both;
}

.filter-section {
  margin-bottom: 33px;
}
.filter-section:last-child {
  margin-bottom: 0;
}
.filter-section > h6 {
  font-size: 16px;
  margin-bottom: 15px;
}
.filter-section .btn-show-more {
  display: inline-flex;
  margin: 6px 0 9px;
  color: #dcdcdc;
  cursor: pointer;
  transition: 150ms ease-in-out;
}
.filter-section .btn-show-more:hover {
  color: #b0976d;
  color: var(--color-primary);
}

.filter-checkbox .form-check {
  margin-bottom: 4px;
  cursor: pointer;
}
.filter-checkbox .form-check:last-child {
  margin-bottom: 0;
}
.filter-checkbox .form-check > label {
  line-height: 24px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .filter-section-wrap {
    position: fixed;
    left: -165px;
    top: 0;
    bottom: 0;
    width: 300px;
    box-shadow: 2.5px 4.33px 40px 5px rgba(12, 31, 46, 0.1);
    opacity: 0;
    visibility: hidden;
    z-index: 200;
    transition: 200ms ease-in-out;
  }
  .filter-section-wrap.active {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  .filter-section-wrap .filter-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 15px 20px;
    background: #b0976d;
    background: var(--color-primary);
  }
  .filter-section-wrap .filter-section-header > i {
    display: block;
    color: #ffffff;
    opacity: 0.8;
    cursor: pointer;
    transition: 150ms ease-in-out;
  }
  .filter-section-wrap .filter-section-header > i:hover {
    opacity: 1;
  }
  .filter-section-wrap .section-title {
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #ffffff;
    border-bottom: none;
  }
  .filter-section-wrap .section-title:after {
    content: none;
  }
  .filter-section-wrap .filter-section-inner {
    position: absolute;
    left: 0;
    top: 58px;
    right: 0;
    bottom: 0;
    padding: 23px 20px 15px;
  }
  .filter-section-wrap .filter-section-inner.custom-scrollbar {
    overflow-x: hidden;
    overflow-y: auto;
  }
}