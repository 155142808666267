.single-feature {
    display: flex;
    justify-content: center;

    .feature-icon {
        margin-right: 13px;

        > i {
            font-size: 36px;
            color: $color-default;
            color: var(--color-primary);
        }
    }

    .feature-details {

        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            font-size: 14px;
            line-height: 22px;
            display: block;
            color: $color-gray;
            margin-top: 2px;
        }
    }

    h6 {
        font-size: 15px;
        line-height: 1.3;
        font-style: normal;
    }
}
