@import 'product-details/_product-details-top',
		'product-details/_product-image-gallery', // prepisane
		'product-details/_product-details-info',
		'product-details/_product-details-bottom',
		'product-details/_product-description',
		'product-details/_product-specification',
		'product-details/_product-reviews';

.product-details-wrap {
	margin-top: 50px;
}
