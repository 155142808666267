//@import 'common/_form-wrap';

.strike {
  color:red;
  text-decoration: line-through;
}

.thamnail_img img {
  max-height: 150px;
}

.error-message {
  float:left;
  color:red;
}

.list-group-item.selected {
  //background: linear-gradient(to right, #0f7404, #5cb917);
  background: #b0976d
}

.nav-link {
  padding: .5rem 2rem;
}



.pointer {
  cursor: pointer;
}
.outline {
  color: transparent !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: $color-default;
}

.sign-in-with {
  font-size: 16px;
  display: block;
  margin-top: 25px;
  color: $color-gray;
  text-align: center;
}

.social-login {
  display: flex;
  margin-top: 13px;
  justify-content: center;

  li {
    a {
      font-size: 16px;
      display: flex;
      height: 40px;
      width: 40px;
      margin: 0 10px;
      color: $color-white;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        opacity: 0.85;
      }

      &.facebook {
        background: #3a559f;
      }

      &.google {
        background: #d94936;
      }
    }
  }
}
